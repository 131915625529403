import { createSlice, current } from "@reduxjs/toolkit";
import _ from "lodash";
const initialState = {
  permissions: [],
  isCheckingPermissionLoader: false,
  isPermissionGranted: false,
};
const permissionsSlice = createSlice({
  name: "permissions",
  initialState,
  reducers: {
    setPermissions: (state, action) => {
      return { permissions: action.payload };
    },
    checkPermissions: (state, action) => {
      const _permissions = current(state.permissions) ?? [];
      console.log("_permissions", _permissions);
      let _find = _.find(_permissions, ["permission_code", action.payload]);
      console.log("_find", _find);

      return !_.isNil(_find) && _find.length > 0
        ? {
            isPermissionGranted: true,
            isCheckingPermissionLoader: false,
          }
        : {
            isPermissionGranted: false,
            isCheckingPermissionLoader: false,
          };
    },
    setPermissionsLoading: (state, action) => {
      return { isCheckingPermissionLoader: action.payload };
    },
  },
});
const { reducer, actions } = permissionsSlice;
export const { setPermissions, checkPermissions, setPermissionsLoading } =
  actions;
export default reducer;
