import React, { useCallback, useEffect } from "react";
import Images from "../../assets/index";
import { useSelector, useDispatch } from "react-redux";
import { changeSelectedLang } from "../../redux/other/othersSlice";
import {
  MenuItem,
  Grid,
  Button,
  Menu,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import { createTheme } from "@mui/material/styles";
import axios from "axios";
import {
  appChangeLanguage,
  appLanguages,
  updateuser,
} from "../../network/networks";
import { GET } from "../../network/constants";
import { getLanguageCode, getLanguageIcon } from "../../common/helper";
import { toast } from "react-toastify";

const theme = createTheme({
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          ":hover": {
            background: "none",
          },
          "& .flag": {
            height: "24px",
            borderRadius: "50%",
            boxShadow: "0px 2px 8px 1px rgba(0, 0, 0, 0.17)",
          },
          ":focus-within": {
            border: "none",
            outline: "none",
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          width: "280px",
          borderRadius: "14px !important",
          padding: "5px",
          "& .language-menu-title": {
            fontSize: "16px",
            color: "#200e32",
            fontFamily: "Poppins",
            fontWeight: 700,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          margin: "5px 10px",
          padding: "10px 15px",
          transition: "all 0.3s",
          fontSize: "14px",
          fontWeight: 600,
          fontFamily: "Poppins",
          color: "#200e32",
          borderRadius: "8px",
          "& .flag": {
            height: "24px",
            borderRadius: "50%",
            boxShadow: "0px 2px 8px 1px rgba(0, 0, 0, 0.17)",
          },
          ":hover": {
            background: "white",
            boxShadow:
              "-4px -4px 16px rgba(97, 39, 127, 0), 4px 16px 24px rgba(170, 182, 194, 0.34), inset 0px 11px 21px rgba(73, 73, 155, 0.06), inset 0px -1px 0px rgba(128, 130, 145, 0.3)",
          },
          "&.active": {
            background: "#6755df",
            color: "white",
            fontWeight: 500,
          },
        },
      },
    },
  },
});

const LanguageSelector = (props) => {
  const { type } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [languageOptions, setLanguageOptions] = React.useState([]);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const selectedLanguage = useSelector((state) => state.other.selectedLanguage);
  const dispatch = useDispatch();
  const handleChange = (language) => {
    if (type === "login") {
      localStorage.setItem("langId", language.value);
      dispatch(changeSelectedLang(language.code));
      window.location.reload();
    } else {
      let updateLang = {
        language: language.value,
      };
      axios
        .put(`${appChangeLanguage}`, updateLang)
        .then((res) => {
          dispatch(changeSelectedLang(language.code));
          window.location.reload();
        })
        .catch((err) => {
          console.log("err ", err.response);
        });
    }
  };
  const getLangauageOptions = useCallback(() => {
    axios
      .get(`${appLanguages}`, GET)
      .then((res) => {
        let data = res.data.map((v, i) => {
          return {
            label: v.name,
            value: v.id,
            code: v.code,
            icon: getLanguageIcon(v.id),
          };
        });
        setLanguageOptions(data);
      })
      .catch((err) => {
        console.log("err", err);
        if (err === "Network Error") {
          toast(err);
        }
      });
  }, []);
  useEffect(() => {
    getLangauageOptions();
  }, [getLangauageOptions]);
  return (
    <div>
      <IconButton
        id="select-language-button"
        aria-controls={open ? "select-language-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <img
          src={
            selectedLanguage === "en-US"
              ? Images.english_icon
              : selectedLanguage === "fr"
              ? Images.french_icon
              : selectedLanguage === "ar"
              ? Images.uae
              : selectedLanguage === "gr"
              ? Images.germany
              : selectedLanguage === "sp"
              ? Images.spain
              : Images.english_icon
          }
          alt="profile"
          className="flag"
        />
      </IconButton>
      <Menu
        id="select-language-menu"
        aria-labelledby="select-language-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {languageOptions.map((language, index) => {
          return (
            <MenuItem
              key={index}
              onClick={() => handleChange(language)}
              className={selectedLanguage === language.code ? "active" : ""}
            >
              <Grid container spacing={3}>
                <Grid item>
                  <img
                    src={language.icon}
                    alt={language.label}
                    className="flag"
                  />
                </Grid>
                <Grid item>{language.label}</Grid>
              </Grid>
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export default LanguageSelector;
