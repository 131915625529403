import { createSlice } from "@reduxjs/toolkit";

const initialState = false;

const recordForegroundSlice = createSlice({
    name:"isRecInForeground",
    initialState,
    reducers:{
      setIsRecInForeground:(state,action) =>{
           state = action.payload;
           return state;
      },
    }
})

const {reducer,actions} = recordForegroundSlice;
export const {setIsRecInForeground} = actions;
export default reducer;