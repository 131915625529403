import ReactDOM from "react-dom";
import { ReactComponent } from "react-formio";
import settingsForm from "../QRScanner/settings";
import { useGeolocated } from "react-geolocated";
import { useState } from "react";
import { Grid, TextField } from "@mui/material";
import _ from "lodash";
import { Button } from "reactstrap";
const GeoLocationComponent = (props) => {
  const [showLoc, setShowLoc] = useState(false);
  const {
    coords,
    getPosition,
    isGeolocationAvailable,
    isGeolocationEnabled,
    positionError,
  } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
    watchLocationPermissionChange: true,
  });
  const getDirection = (degrees, isLongitude) =>
    degrees > 0 ? (isLongitude ? "E" : "N") : isLongitude ? "W" : "S";

  const formatDegrees = (degrees, isLongitude) =>
    `${0 | degrees}° ${
      0 | (((degrees < 0 ? (degrees = -degrees) : degrees) % 1) * 60)
    }' ${0 | (((degrees * 60) % 1) * 60)}" ${getDirection(
      degrees,
      isLongitude
    )}`;
  return (
    <div
      className=""
      style={{
        background: "#eeecec",
        borderRadius: "10px",
        padding: "10px",
      }}
    >
      {showLoc && (
        <>
          <div className="text-slate-900">
            <div className="m-8 font-bold text-l">
              {!isGeolocationAvailable ? (
                <div>Your browser does not support Geolocation.</div>
              ) : !isGeolocationEnabled ? (
                <div>Geolocation is not enabled.</div>
              ) : coords ? (
                <div>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <TextField
                        disabled
                        label="Latitude"
                        defaultValue={coords.latitude}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        disabled
                        label="Longitude"
                        defaultValue={coords.longitude}
                        fullWidth
                      />
                    </Grid>
                    {!_.isNil(coords.altitude) && (
                      <Grid item xs={4}>
                        <TextField
                          disabled
                          label="Altitude"
                          defaultValue={coords.altitude}
                          fullWidth
                          helperText={`approximately ${coords.altitude} meters above sea level.`}
                        />
                      </Grid>
                    )}
                  </Grid>
                </div>
              ) : (
                <div>Getting the location data&hellip;</div>
              )}
              {!!positionError && (
                <div>
                  <br />
                  Last position error:
                  <pre>{JSON.stringify(positionError)}</pre>
                </div>
              )}
            </div>
          </div>
          <br />
        </>
      )}

      <div className="flex items-center space-x-2">
        <Button
          style={{
            // background: props?.component?.disabled ? "gray" : "#ffc107",
            color: "white",
            borderRadius: "10px",
          }}
          color={"warning"}
          disabled={props?.component?.disabled}
          onClick={() => {
            getPosition();
            setShowLoc(true);
            let _data = {
              coords: {
                latitude: coords.latitude,
                longitude: coords.longitude,
              },
            };
            props.onChange(_data);
            console.log("_data ", _data);
          }}
          type="button"
        >
          Get location
        </Button>
      </div>
    </div>
  );
};

export default class GeoLocationComponentField extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation:  string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: "GeoLocation",
      icon: "square",
      group: "Data",
      documentation: "",
      weight: -10,
      schema: GeoLocationComponentField.schema(),
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   * @param component
   * @param options
   * @param data
   * @param sources
   * @returns {*}
   */
  init() {
    super.init();
  }
  get inputInfo() {
    const info = super.inputInfo;
    return info;
  }
  static schema() {
    return ReactComponent.schema({
      type: "GeoLocationComponent",
      label: "GeoLocationComponent",
      key: "geolocation",
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    return ReactDOM.render(
      <GeoLocationComponent
        component={this.component}
        value={this.dataValue} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.
      />,
      element
    );
  }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
}
