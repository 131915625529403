import Images from "../assets";
import Strings from "../values/strings";
export default {
  validateMobileno(numericStr) {
    const phoneno = /^\+[1-9]{1}[0-9]{3,14}$/;
    if (numericStr.match(phoneno)) {
      return true;
    }
    return false;
  },

  validateSpace(value) {
    const spaceRegex = new RegExp("\\s");
    return spaceRegex.test(value);
  },

  validateNumber(number) {
    const numberFormet = /^[0-9]*$/;
    return numberFormet.test(number);
  },

  validateEmail(email) {
    console.log("email", email);
    const regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    console.log("email test", regex.test(email));
    return regex.test(email);
  },

  stringHasSpecialChars(str) {
    const regex = /^[a-zA-Z0-9 ]*$/;
    return regex.test(str);
  },
  validateForm(formData) {
    const iterator = Object.keys(formData);
    let error = false;
    if (iterator.length > 0) {
      const validationArr = [];
      for (let index = 0; index < iterator.length; index++) {
        if (formData[iterator[index]] === undefined) {
          formData[iterator[index]] = "";
        }
        if (formData[iterator[index]].required) {
          if (
            formData[iterator[index]].value !== undefined &&
            formData[iterator[index]].value !== "" &&
            formData[iterator[index]].value !== null
          ) {
            if (
              formData[iterator[index]].propname === "username" ||
              formData[iterator[index]].propname === "username" ||
              formData[iterator[index]].propname === "firstname" ||
              formData[iterator[index]].propname === "lastname" ||
              formData[iterator[index]].propname === "organization"
            ) {
              if (this.validateSpace(formData[iterator[index]].value)) {
                formData[iterator[index]].err = true;
                formData[iterator[index]].errMsg = Strings.no_space;
              } else {
                formData[iterator[index]].err = false;
                formData[iterator[index]].errMsg = "";
              }
            } else if (
              formData[iterator[index]].propname === "password" ||
              formData[iterator[index]].propname === "Password"
            ) {
              if (formData[iterator[index]].value.length < 6) {
                formData[iterator[index]].err = true;
                formData[iterator[index]].errMsg = Strings.min_six_char;
              } else if (this.validateSpace(formData[iterator[index]].value)) {
                formData[iterator[index]].err = true;
                formData[iterator[index]].errMsg = Strings.no_space;
              } else {
                formData[iterator[index]].err = false;
                formData[iterator[index]].errMsg = "";
              }
            } else if (
              formData[iterator[index]].propname === "password_confirmation"
            ) {
              if (formData[iterator[index]].value.length < 6) {
                formData[iterator[index]].err = true;
                formData[iterator[index]].errMsg = Strings.min_six_char;
              } else if (this.validateSpace(formData[iterator[index]].value)) {
                formData[iterator[index]].err = true;
                formData[iterator[index]].errMsg = Strings.no_space;
              } else if (
                formData.password.value !== formData[iterator[index]].value
              ) {
                formData[iterator[index]].err = true;
                formData[iterator[index]].errMsg = "Password doesn't matched";
              } else {
                formData[iterator[index]].err = false;
                formData[iterator[index]].errMsg = "";
              }
            } else if (
              formData[iterator[index]].propname === "email" ||
              formData[iterator[index]].propname === "Email"
            ) {
              if (!this.validateEmail(formData[iterator[index]].value)) {
                formData[iterator[index]].err = true;
                formData[iterator[index]].errMsg = Strings.enter_email;
              } else {
                formData[iterator[index]].err = false;
                formData[iterator[index]].errMsg = "";
              }
            } else {
              formData[iterator[index]].err = false;
              formData[iterator[index]].errMsg = "";
            }
          } else {
            formData[iterator[index]].err = true;
            formData[iterator[index]].errMsg = Strings.required_field;
          }
        } else {
          formData[iterator[index]].err = false;
          formData[iterator[index]].errMsg = "";
        }
        validationArr.push(formData[iterator[index]].err);
      }
      if (validationArr.indexOf(true) !== -1) {
        error = true;
      } else {
        error = false;
      }
    } else {
      error = true;
    }
    return new Promise((resolve) => {
      resolve({ error, formDataObj: formData });
    });
  },
};


export const getLanguageCode = (id) => {
  switch (id) {
    case 1:
      return "en-US"
    case 2:
      return "fr"
    case 3:
      return "ar"
    case 4:
      return "gr"
    case 5:
      return "sp"
    case 6:
      return "pr"
    default:
      return "en-US"
  }
}
export const getLanguageIcon = (id) => {
  switch (id) {
    case 1:
      return Images.english_icon
    case 2:
      return Images.french_icon
    case 3:
      return Images.uae
    case 4:
      return Images.germany
    case 5:
      return Images.spain
    case 6:
      return Images.portugese
    default:
      return Images.english_icon
  }
}