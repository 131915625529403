import React, { useState } from "react";
import LoginFooter from "../../components/LoginFooter/LoginFooter";
import LoginHeader from "../../components/LoginHeader/LoginHeader";
import DialogForgotPassword from "../Login/DialogForgotPassword";
import LoginPage from "../Login/LoginPage";
import SEOAIRA from "../../common/SEOAIRA";
import { Box } from "@mui/material";

const LandingPage = () => {
  const [dialogChangePassword, setDialogChangePassword] = useState(false);

 
  const toggleChangePassword = (event) => {
    event.preventDefault();
    setDialogChangePassword(!dialogChangePassword);
  };

  return (
    <Box
      sx={{
        position: "relative",
        minHeight: "100vh",
      }}
    >
      <LoginHeader />
      <LoginPage
        toggleChangePassword={toggleChangePassword}
      />
      <DialogForgotPassword
        open={dialogChangePassword}
        handleClose={() => setDialogChangePassword(false)}
      />
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          right: 0,
        }}
      >
        <LoginFooter />
      </Box>
    </Box>
  );
};

export default LandingPage;
