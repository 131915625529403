import { useState } from "react";
import ReactDOM from "react-dom";
import { ReactComponent } from "react-formio";
import settingsForm from "./settings";
import QrReader from "react-qr-reader";
import styled from "styled-components";
import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import CustomRSelect from "../../../../../../components/CustomRSelect/CustomRSelect";
import { ThreeDots } from "react-loader-spinner";
import Colors from "../../../../../../values/colors";
import { useSelector } from "react-redux";
import _ from "lodash";
const ScannerUI = styled.ul`
  .App {
    font-family: "Montserrat", sans-serif;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .Button {
    color: white;
    background-color: blue;
    padding: 10px;
    width: 100px;
    font-weight: 800;
    letter-spacing: 2;
    cursor: pointer;
    border-radius: 10px;
    transform: all 1s cubic-bezier(0.42, 0, 0.17, 0.71);
    margin: 10px;
  }

  .Button:hover {
    background-color: red;
  }

  .dialog {
    padding: 20px;
    background: white;
    position: absolute;
    z-index: 500;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(37, 37, 37, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dialog-content {
    background-color: white;
    margin: 0;
    width: 400px;
    height: 400px;
    display: flex;
    padding: 20px;
    flex-direction: column;
    border-radius: 10px;
    align-items: flex-start;
    justify-content: space-between;
    overflow-y: auto;
  }

  .close {
    width: 100%;
    align-items: flex-end;
    justify-content: flex-end;
    display: flex;
  }

  .close > button {
    height: 36px;
    width: 36px;
    border: none;
    background: transparent;
    cursor: pointer;
  }

  .title {
    font-weight: 700;
    font-size: 1.5rem;
    margin: 0;
    padding-bottom: 5px;
  }

  @keyframes anime {
    0% {
      font-size: 10px;
    }
    30% {
      font-size: 15px;
    }
    100% {
      font-size: 12px;
    }
  }

  .detail {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: 0;
    height: 40px;
    border-bottom: 1px dashed;
    animation: slidein 2s;
  }
  .detail-first-child {
    border-top: 1px dashed;
    margin-top: 20px;
  }

  .detail-header {
    font-weight: bold;
    font-size: 1rem;
  }

  .detail-content {
    font-size: 1rem;
    padding-left: 5px;
  }

  .green {
    color: rgb(153, 255, 0);
  }
  .description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: fit-content;
  }
  .red {
    color: red;
  }

  .message {
    font-size: 0.8rem;
  }
  .small {
    font-size: 0.9rem;
  }

  @keyframes slidein {
    from {
      margin-left: 100%;
      width: 300%;
    }

    to {
      margin-left: 0%;
      width: 100%;
    }
  }

  .errorMessage {
    display: flex;
    align-content: center;
    align-items: center;
  }
`;

const QRScannerComponent = (props) => {
  const [selected, setSelected] = useState("environment");
  const [startScan, setStartScan] = useState(false);
  const [loadingScan, setLoadingScan] = useState(false);
  const [data, setData] = useState("");
  const customStyles = {
    menu: (provided) => ({
      ...provided,
      background: Colors.airaPrimary,
    }),
    option: (provided) => ({
      ...provided,
      background: Colors.airaPrimary,
      // color: muiTheme.palette.text.primary,
      "&:hover": {
        background: Colors.airaPrimary,
        color: Colors.white,
      },
      "&:focus": {
        background: Colors.airaPrimary,
        color: Colors.white,
      },
    }),
  };
  const handleScan = async (scanData) => {
    setLoadingScan(true);
    console.log(`loaded data data`, scanData);
    console.log(`loaded >>>`, scanData);
    if (scanData && scanData !== "" && !_.isNil(scanData)) {
      setData(scanData);
      props.onChange(scanData);
      setStartScan(false);
      setLoadingScan(false);
      // setPrecScan(scanData);
    }
  };
  const handleError = (err) => {
    console.error(err);
  };
  return (
    <ScannerUI>
      <Typography style={{ color: Colors.grey, fontSize: "12px" }}>
        Scan QR Code From Here
      </Typography>
      <div className="App">
        <Grid container spacing={2}>
          {data !== "" && (
            <Grid
              item
              xs={6}
              style={{
                textWrap: "wrap",
                overflowWrap: "anywhere",
              }}
            >
              <Typography
                style={{
                  textAlign: "initial",
                  border: "1px solid #dbdbdb",
                  padding: "7px",
                  borderRadius: "5px",
                }}
              >
                {data}
              </Typography>
            </Grid>
          )}
          <Grid item xs={6} style={{ display: "flex" }}>
            <Button
              variant="outlined"
              onClick={() => {
                if (!startScan) {
                  setLoadingScan(false);
                }
                setStartScan(!startScan);
              }}
              fullWidth
              sx={{
                margin: "0px 5px",
              }}
              disabled={props?.component?.disabled}
            >
              {startScan
                ? "Stop Scan"
                : data !== ""
                ? "Scan Again"
                : "Start Scan"}
            </Button>
            {startScan && (
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={[
                  { label: "Back Camera", value: "backCamera" },
                  { label: "Front Camera", value: "frontCamera" },
                ]}
                fullWidth
                renderInput={(params) => (
                  <TextField {...params} label="Select" />
                )}
                onChange={(data) => console.log(data)}
                // menuPortalTarget={document.querySelector(".MuiGrid-root")}
              />
              // <CustomRSelect
              //   fullWidth
              //   type="select"
              //   id="previosVisits"
              //   isSearchable={false}
              //   menuPortalTarget={document.querySelector(".MuiGrid-root")}
              //   onChange={(data) => console.log(data)}
              //   options={[
              //     { label: "Back Camera", value: "backCamera" },
              //     { label: "Front Camera", value: "frontCamera" },
              //   ]}
              //   styles={customStyles}
              // />
            )}
          </Grid>
        </Grid>
        <br />
        {startScan && (
          <>
            <QrReader
              facingMode={selected}
              delay={1000}
              onError={handleError}
              onScan={handleScan}
              // chooseDeviceId={()=>selected}
              style={{ width: "300px" }}
            />
          </>
        )}
        {console.log("loadingScan", loadingScan)}
        {loadingScan && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography style={{ marginRight: "10px" }}>Loading</Typography>
            <ThreeDots
              height="50"
              width="50"
              radius="9"
              color={Colors.airaPrimary}
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        )}
      </div>
    </ScannerUI>
  );
};

export default class QRScannerComponentField extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation:  string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: "QRScanner",
      icon: "square",
      group: "Data",
      documentation: "",
      weight: -10,
      schema: QRScannerComponentField.schema(),
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   * @param component
   * @param options
   * @param data
   * @param sources
   * @returns {*}
   */
  init() {
    super.init();
  }
  get inputInfo() {
    const info = super.inputInfo;
    return info;
  }
  static schema() {
    return ReactComponent.schema({
      type: "QRScannerComponent",
      label: "QRScannerComponent",
      key: "QrScanner",
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    return ReactDOM.render(
      <QRScannerComponent
        component={this.component} // These are the component settings if you want to use them to render the component.
        value={this.dataValue} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.
      />,
      element
    );
  }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
}
