const Images = {
  logo: require("./logo.png"),
  logo_dark: require("./logoDark.png"),
  logo_onet: require("./onet.png"),

  aira_logo_light_svg: require("./aira_logo_light.svg"),
  aira_logo_dark_svg: require("./aira_logo_dark.svg"),

  logo_monogram: require("./logo-monogram.svg"),
  robot: require("./robot_ai.png"), //ES
  password: require("./password.png"),
  confirm_password: require("./password_1.png"),
  profileLogin: require("./profile.png"),
  google: require("./google.png"),
  language: require("./language.png"),
  waving_hand: require("./waving-hand.png"),
  dash_robot: require("./dash_robot.png"),
  models: require("./models.png"),
  classification: require("./classification.png"),
  regression: require("./regression.png"),
  flow: require("./flow.png"),
  more: require("./more.png"),
  attach_icon: require("./icons/attach.png"),
  image_icon: require("./icons/image.png"),

  // Designer Icons
  designer_run: require("./designer_run.png"),

  //outline nav icons
  chat: require("./icons/outline_chat.png"),
  edit_square: require("./icons/outline_edit_square.png"),
  notification: require("./icons/outline_notification.png"),
  profile: require("./icons/outline_profile.png"),

  light_chat: require("./icons/light_outline_chat.png"),
  light_edit_square: require("./icons/light_outline_edit.png"),
  light_notification: require("./icons/light_outline_notification.png"),
  light_profile: require("./icons/light_outline_profile.png"),

  boldPaper: require("./icons/bold-paper.png"),
  bulkComputer: require("./icons/bulk-computer.png"),
  boldDanger: require("./icons/bold-danger.png"),

  logout: require("./icons/light-outline-logout.png"),
  user: require("./user.png"),
  visaCard: require("./card.png"),

  animatedRobot: require("./animatedRobot.gif"),
  notfound: require("./notfound.png"),

  success: require("./success-img.png"),
  error: require("./error-img.png"),

  english_icon: require("./icons/english.svg").default,
  french_icon: require("./icons/france.svg").default,
  uae: require("./icons/uae.svg").default,
  spain: require("./icons/spain.svg").default,
  germany: require("./icons/germany.svg").default,
  portugese: require("./icons/portugese.svg").default,
  emptyState: require("./empty-state-img.png"),

  emptyState1: require("./empty-state-img_prev_ui.png"),

  normalModels: require("./normal.png"),
  errorModels: require("./error.png"),
  successModels: require("./success.png"),
  goal: require("./goal.png"),
  goal1: require("./goal1.png"),
  landing: require("./landing.png"),

  //nav
  navicons: {
    dashboard: require("./icons/iconsTableauDeBords.png"),
    usergroup: require("./icons/icons-light-outline-3-user.png"),
    category: require("./icons/icons-light-outline-category.png"),
    connection: require("./icons/icons-light-outline-3-user.png"),
    chart: require("./icons/icons-light-outline-chart.png"),
    document: require("./icons/icons-light-outline-document.png"),
    info: require("./icons/icons-light-outline-info-circle.png"),
    lists: require("./icons/icons-light-outline-lists.png"),
    setting: require("./icons/icons-light-outline-setting.png"),
    timecircle: require("./icons/icons-light-outline-time-circle.png"),
    work: require("./icons/icons-light-outline-work.png"),

    dashboardActive: require("./icons/tableau-de-bords.png"),
    usergroupActive: require("./icons/iconsLightOutline3User.png"),
    categoryActive: require("./icons/iconsLightOutlineCategory.png"),
    connectionActive: require("./icons/iconsLightOutline3User.png"),
    chartActive: require("./icons/iconsLightOutlineChart.png"),
    documentActive: require("./icons/iconsLightOutlineDocument.png"),
    infoActive: require("./icons/iconsLightOutlineInfoCircle.png"),
    listsActive: require("./icons/iconsLightOutlineDocument.png"),
    settingActive: require("./icons/iconsLightOutlineSetting.png"),
    timecircleActive: require("./icons/iconsLightOutlineTimeCircle.png"),
    workActive: require("./icons/iconsLightOutlineWork.png"),

    model: require("./icons/layer.png"),
    modelActive: require("./icons/layer1.png"),
  },

  iconsBlueOutlineWork: require("./icons/iconsBlueOutlineWork.png"),
  iconsBlueOutlineAddUser: require("./icons/iconsBlueOutlineAddUser.png"),
  iconsBlueOutlineCategory: require("./icons/iconsBlueOutlineCategory.png"),
  iconsBlueOutlineChart: require("./icons/iconsBlueOutlineChart.png"),
  iconsLightOutlineRefresh: require("./icons/iconsLightOutlineRefresh.png"),

  iconsBlueOutlineEditSquare: require("./icons/iconsBlueOutlineEditSquare.png"),
  iconsBlueOutlineOptions: require("./icons/iconsBlueOutlineOptions.png"),
  iconsBlueOutlineDelete: require("./icons/iconsBlueOutlineDelete.png"),
  iconsBlueOutlineUpload: require("./icons/iconsBlueOutlineUpload.png"),
  iconsBlueOutlineArrow: require("./icons/right-arrow-circular-button-outline.png"),
  uiIconArrowSmallRight: require("./icons/uiIconArrowSmallRight.png"),

  iconsLightOutlineDanger: require("./icons/iconsLightOutlineDanger.png"),
  iconsLightOutlineTickSquare: require("./icons/iconsLightOutlineTickSquare.png"),
  iconsLightOutlinePlay: require("./icons/iconsLightOutlinePlay.png"),
  iconsLightOutlineProfile: require("./icons/iconsLightOutlineProfile.png"),
  downArrow: require("./icons/down-arrow.png"),

  add: require("./icons/plus.png"),

  export: require("./icons/export.png"),
  printer: require("./icons/printer.png"),
  rubber: require("./icons/rubber.png"),

  iconsTablesArrowGrip: require("./icons/iconsTablesArrowGrip.png"),
  iconsTablesArrowSmallUp: require("./icons/iconsTablesArrowSmallUp.png"),
  iconsTablesArrowUpDown: require("./icons/iconsTablesArrowUpDown.png"),
  add_user: require("./icons/add-user.png"),
  pendingStatus: require("./icons/pendingStatus.png"),
  clouds: require("./clouds.png"),

  text: require("./BITools/text.png"),
  image: require("./BITools/photo.png"),
  column: require("./BITools/column.png"),
  certificate: require("./certificate.png"),
  uploadFileCloud: require("./upload.png"),

  email: require("./BITools/email.png"),
  resume: require("./BITools/resume.png"),
  invoice: require("./BITools/invoice.png"),
  purchasing: require("./BITools/purchasing.png"),
  form: require("./BITools/form.png"),
  other: require("./BITools/other.png"),
  graph: require("./BITools/graph.png"),

  basic_line: require("./MuiIcons/line-chart.png"),
  stepline: require("./MuiIcons/stepline-chart.png"),
  basic_bar: require("./MuiIcons/bar-chart.png"),
  stacked_bar: require("./MuiIcons/stacked-bar.png"),
  group_bar: require("./MuiIcons/grouped-chart.png"),
  basic_area: require("./MuiIcons/area-chart.png"),
  spline_area: require("./MuiIcons/spline-area.png"),
  basic_line_graph: require("./MuiIcons/line-chart.png"),

  pie: require("./MuiIcons/pie.png"),
  donut: require("./MuiIcons/donut.png"),
  monochrome: require("./MuiIcons/monochrome.png"),
  polar_area: require("./MuiIcons/polar-area.png"),
  radial_bar: require("./MuiIcons/radial.png"),
  funnel: require("./MuiIcons/funnel.png"),
  radial_bar_chart: require("./MuiIcons/circle.png"),
  StrokedGauge: require("./MuiIcons/loading.png"),
  blockData: require("./MuiIcons/text.png"),
  table: require("./MuiIcons/cells.png"),
};
export default Images;
