import $ from "jquery";
import _ from "lodash";
export const getGridData = (gridName) => {
  const element = document.getElementById("box");
  let grd = element.getElementsByClassName(gridName);
  const trs = document.querySelectorAll("table tr");
  const result = [];
  for (let tr of trs) {
    let th_td = tr.getElementsByTagName("td");
    let th_td_array = Array.from(th_td);
    th_td_array = th_td_array.map((tag) => {
      let inputTag = tag.querySelector("input,select,checkbox");
      if (inputTag !== undefined && inputTag !== null) {
        if (inputTag.type === "checkbox") {
          return inputTag.checked;
        } else {
          return inputTag.value;
        }
      }
      return null;
    });
    result.push(th_td_array);
  }
  let data = [];
  result.forEach((v, i) => {
    if (v.length > 1 && v !== undefined) {
      data.push(v);
    }
  });
  return data;
};

// In progress
const getGridDataNew = (gridName) => {
  var res = [];
  $(`.formio-component-${gridName}`)
    .find(".table")
    .find("tbody")
    .find("tr")
    .map(function (index, elem) {
      let new_data = [];
      $("input", this).each(function () {
        var d = $(this).val() || $(this).text();
        new_data.push(d);
      });
      res.push(new_data);
    });
  console.log("res", res);
  return res;
};

export const getNoOfRows = (gridName) => {
  let data = getGridData(gridName);
  return data.length;
};

export const getGridValue = (gridName, row, column) => {
  let data = getGridData(gridName);
  let value = "";
  if (data.length >= row) {
    if (getGridHeader(gridName).length >= column) {
      value = data[row][column];
    } else {
      value = "Column not found";
    }
  } else {
    value = "Row not found";
  }
  return value;
};
export const setGridValue = (gridName, row, column, _value) => {
  const element = document.getElementById("box");
  let grd = element.getElementsByClassName(gridName);
  const trs = document.querySelectorAll("table tr");
  const result = [];
  for (let tr of trs) {
    let th_td = tr.getElementsByTagName("td");
    let th_td_array = Array.from(th_td);
    th_td_array = th_td_array.map((tag) => {
      let inputTag = tag.querySelector("input");
      if (inputTag !== undefined && inputTag !== null) {
        return inputTag;
      }
      return null;
    });
    result.push(th_td_array);
  }
  let data = [];
  result.forEach((v, i) => {
    if (v.length > 1 && v !== undefined) {
      data.push(v);
    }
  });
  let field = data[row];
  field[column].value = _value;
};
export const getGridHeader = (gridName) => {
  const element = document.getElementById("box");
  let grd = element.getElementsByClassName(gridName);
  const trs = document.querySelectorAll("table tr");
  const result = [];
  for (let tr of trs) {
    let th_td = tr.getElementsByTagName("th");
    let th_td_array = Array.from(th_td);
    th_td_array = th_td_array.map((tag) => {
      return tag.textContent.trim();
    });
    result.push(th_td_array);
  }
  return result[0];
};

export const getInputValue = (fieldName) => {
  let element = document.getElementById("box");
  let element_input = element.querySelector(
    `.formio-component-${fieldName} input,select`
  );
  console.log("element_input===>", element_input.value);
  return element_input.value;
};
export const deleteGridRow = (gridName, index) => {
  var table = document.querySelector(
    `.formio-component-${gridName} table tbody`
  );
  table.deleteRow(index);
};
export const addGridRow = (gridName) => {
  var table = document.querySelector(
    `.formio-component-${gridName} table tbody`
  );
  table.insertRow(0);
};
/// Using Jquery
export const getElement = (eleName) => {
  let data = $(eleName);
  return data;
};
export const getElementValue = (eleName) => {
  return $(eleName).val();
};
export const getText = (eleName) => {
  return $(eleName).text();
};

export const getElementAttrValue = (eleName, attrName) => {
  return $(eleName).attr(attrName);
};

export const hideElement = (eleName) => {
  $(eleName).hide("slow", "linear");
};
export const showElement = (eleName) => {
  $(eleName).show("slow", "linear");
};
export const hideColumn = (eleName, columnInd) => {
  $(`.formio-component-${eleName} table`)
    .find("tr")
    .each(function (key, value) {
      $(this)
        .find(`th:nth-child(${columnInd}),td:nth-child(${columnInd})`)
        .hide("slow", "linear");
    });
};
export const showColumn = (eleName, columnInd) => {
  $(`.formio-component-${eleName} table`)
    .find("tr")
    .each(function (key, value) {
      $(this)
        .find(`th:nth-child(${columnInd}),td:nth-child(${columnInd})`)
        .show("slow", "linear");
    });
};
export const getControl = (eleName, rowInd, columnInd) => {
  let _control = "";
  $(`.formio-component-${eleName} table`)
    .find("tr")
    .each(function (key, value) {
      if (key == rowInd) {
        _control = $(this).find(`td:nth-child(${columnInd})`); //.css("backgroundColor", "yellow");
      }
    });
  if (!_.isEmpty(_control)) {
    return _control;
  }
};

export const getSummary = (gridName, columnInd) => {
  let data = getGridData(gridName);
  if (!_.isNil(data)) {
    let total = 0;
    data.map((v, i) => {
      v.map((v1, i1) => {
        if (i1 + 1 == columnInd) {
          total = total + JSON.parse(v[i1]);
        }
      });
    });
    return total;
  }
};
export const callExecuteJsFunction = (jsString) => {
  eval(jsString);
};
