import { createSlice } from "@reduxjs/toolkit";

let selectedLanguage = localStorage.getItem("selectedLanguage");
const initialState = {
  selectedLanguage:
    selectedLanguage === undefined ||
    selectedLanguage === null ||
    selectedLanguage === "null"
      ? "en-US"
      : selectedLanguage,
  designerData: [],
};

const othersSlice = createSlice({
  name: "other",
  initialState,
  reducers: {
    changeSelectedLang: (state, action) => {
      localStorage.setItem("selectedLanguage", action.payload);
      state.selectedLanguage = action.payload;
    },
    changeDesignerData: (state, action) => {
      state.DesignerData = action.payload;
    },
  },
});

export const { changeSelectedLang, changeDesignerData } = othersSlice.actions;

export default othersSlice.reducer;
