import LocalizedStrings from "react-localization";
let Strings = new LocalizedStrings({
  "en-US": {
    account_number: "Account number",
    action: "Action",
    activate_all: "Activate",
    library: "Library",
    activation: "Activation",
    active_users: "Active users",
    activities: "Activities",
    add_a_new_card: "Add a new card",
    add_bot: "Add Bot",
    add_digital_agent: "Add Digital Agent",
    add_model: "Add Model",
    add_new_instance: "Add New Instance",
    add_new_job: "Add New Job",
    add_new_connection: "Add New Connection",
    add_new_form: "Add New Form",
    add_new_model: "Add new Model",
    add_or_manage_user_roles_and_departments:
      "Add or manage user roles and departments",
    add_or_manage_users_and_groups: "Add or manage user roles and departments",
    add_user: "Add User",
    add: "Add",
    address: "Address",
    admin_admin: "Admin",
    administration: "Administration",
    alerts_and_notifications: "Alerts and notifications",
    alerts: "Alerts",
    allow_you_to_authenticate_users_against_an_external_idp_using_the_saml:
      "Allow you to authenticate users against an external Idp using the SAML",
    already_exist: "This name is already exist",
    another_msg: "Another jobs is trigger for fetch",
    app_id: "Application identifier",
    application_name: "Application Name",
    apply: "Apply",
    assertion_customer_service: "Affirmation of Customer Service",
    assets: "Assets",
    assoiciated_queue: "Assosiated queue",
    auto_layout: "Automatic layout",
    auto_retry: "Auto retry",
    avatar_helps_you_and_your_teammates_recognize_you:
      "Avatar helps you and your teammates recognize you",
    back_to_models: "Back to models",
    back_to_projects: "Back to projects",
    bill: "Bill",
    bot_name: "Bot Name",
    bot: "Bot",
    bots: "Bots",
    digital_agent_name: "Digital Agent Name",
    digital_agent: "Digital Agent",
    digital_agents: "Digital Agents",
    buyer_group: "Buyer Group",
    cancel: "Cancel",
    card_holder: "Card holder",
    case_no: "Case No.",
    case_title: "Case Title",
    cases: "Cases",
    casiers: "Casiers",
    center_code: "Code",
    center: "Center",
    certificate: "Certificate",
    change_your_smtp_settings: "Change your SMTP settings",
    city: "City",
    clear: "Clear",
    code: "Code",
    common_fields: "Common fields",
    communication: "Communication",
    completed: "Completed",
    confirm_password: "Confirm Password",
    confirm_your_password: "Confirm your password",
    change_password: "Change Password",
    congratulations: "Congratulations",
    connection_history: "Connection History",
    connection_type: "Connection type",
    connections: "Connections",
    contact_number: "Contact number",
    cost_center: "Cost Center",
    country: "Country",
    create_a_project: "Create a project",
    create_an_invoice: "Create an invoice",
    create_new_instance: "Create New Instance",
    create_new_job: "Create Job",
    create_new_project: "Create Project",
    create_new_user: "Create User",
    create_version: "Create a version",
    created_at: "Created at",
    created_by: "Created By",
    creation_date: "Creation date",
    creation: "Creation",
    credential_request: "Credential Request",
    credentials: "Credentials",
    current_user: "Current User",
    custom_fields: "Custom fields",
    dashboard: "Dashboard",
    data_source: "Data Source",
    deactivate_my_account: "Deactivate my account",
    delete_all: "Delete",
    delete_avatar: "Delete Avatar",
    departments_list: "List of departments",
    departments: "Departments",
    description: "Description",
    design: "Design",
    designation: "Designation",
    digibot_preview: "Digibot preview",
    digital_agent_name: "Digital Agent Name",
    directories: "Relevant",
    disable_all: "Disable",
    disableTrigger: "Disable trigger at",
    disconnect: "Disconnect",
    document_language: "Document Language",
    document_management: "Document management",
    document_type: "Document Type",
    document: "Document",
    documents: "Documents",
    due_date: "Due Date",
    edit_model: "Edit Model",
    edit_instance: "Edit Instance",
    edit_profile: "Edit Profile",
    email: "Email",
    empower_tomorrow: "Empower tomorrow",
    enable_sla_msg: "Enable SLA for this queue",
    enable: "Activer",
    end_time: "End Time",
    enter_email: "Please enter valid email",
    enter_full_name: "Please Enter Full Name",
    enter_role: "Please Select Role",
    enter_username: "Enter Username",
    enter_your_new_password: "Enter your new password",
    entity_id: "Entity ID",
    entity: "Entity",
    events: "Events",
    excel: "Excel",
    execute: "Exempt",
    executed_jobs: "Executed Jobs",
    execution_history: "Execution History",
    execution_id: "Execution Id",
    execution_time: "Execution Time",
    execution: "Execution",
    expires_on: "Expires on",
    failed: "Failed",
    first_name: "First name",
    flight_info: "Flight Info",
    forgot_your_password: "Forgot your password ?",
    github: "GitHub",
    good_afternoon: "Good Afternoon",
    good_evening: "Good Evening",
    good_morning: "Good Morning",
    group_actions: "Group actions",
    groupes_list: "List of groups",
    groupes: "Groups",
    guest_users: "Guest Users",
    hello: "Hello",
    historical: "Historical",
    history_of_executions: "History of executions",
    house: "HOUSE NUM",
    id: "ID",
    idp_spotted: "IDP spotted",
    idp: "IDP",
    in_progress: "In Progress",
    in_seconds: "(In Seconds)",
    inactive: "Inactive",
    inbox: "Inbox",
    instance_name: "Instance Name",
    integration_service: "Integration service",
    internal_order: "Internal Order",
    invoice_name: "Invoice name",
    job_description: "Job Description",
    job_list: "Job List",
    job_name: "Job Name",
    job_status: "Job Status",
    job_version: "Job Version",
    job: "Job",
    jobs: "Jobs",
    general_job: "General Jobs",
    language: "Language",
    last_name: "Last name",
    last_updated_at: "Last Update At",
    learning_instance: "Learning Instance",
    create_learning_instance: "Create Learning Instance",
    licenses: "Licence",
    list_of_bots: "List of Bots",
    login_success_message: "You have logged in successfully!",
    login_history: "Login history",
    login_url: "Connection URL",
    login: "Log in",
    logout_url: "Connection URL",
    logs: "Logs",
    real_time_logs: "Real Time Logs",
    manager: "Manager",
    mandatory: "mandatory",
    max_item_msg: "Maximum of pending and running jobs allowed simultaneousaly",
    max_of_retry: "Max of retry",
    means_of_payments: "Means Of Payments",
    members: "Members",
    min_item_msg: "Minimum of items to trigger the first job",
    min_six_char: "Password should contain minimum 6 characters",
    mission_allocation: "Mission Allocation",
    model_flow: "Model Flow",
    model_name: "Model Name",
    models: "Models",
    modified: "Modified",
    modify_my_profile: "Modify My Profile",
    month: "Month",
    monthly_activity_overview: "Monthly activity overview",
    monthly: "Monthly",
    my_bots: "My Bots",
    my_planning: "My Planning",
    my_robots: "My robots",
    name_and_description: "Name & Description",
    name_taken: "UserName is Taken",
    name: "Name",
    navigator: "Navigator",
    need_help: "Need Help ?",
    new_password: "New password",
    no_job_found: "No Job Found",
    no_records: "No records available",
    no_space: "No space allowed",
    nonWorkingdaysRest: "Non working days restrictions",
    not_running: "Not running",
    notepad: "Notepad",
    nothing_here: "Nothing Here",
    notifications: "Notifications",
    ok_understood: "OK understood !",
    optional: "optional",
    orchestrator: "Orchestrator",
    org_name: "The name of your organization",
    organization: "Organization",
    other: "Other",
    owner: "Owner",
    participated: "Participated",
    password_not_match: "Password is not match",
    password_reset_link_msg:
      "A link has been sent to you by email to reset your password",
    password: "Password",
    pause: "Break",
    paused: "Paused",
    payment_methods: "Payment methods",
    pending_tasks: "Pending tasks",
    permissions: "Permissions",
    phone: "Phone",
    planning: "Planning",
    platform: "Platform",
    please_enter_your_email_to_reset_your_password:
      "Please enter your email to reset your password",
    please_select_a_task: "Please Select a Task",
    post: "Post",
    previews: "Previews",
    priority: "Priority",
    process_mining: "Process Mining",
    process: "Process",
    processName: "Process Name",
    production: "Production",
    profile: "Profile",
    project_desc: "Project Description",
    project_due_date: "Project Due Date",
    project_language: "Project Language",
    project_name: "Project Name",
    Project_owner: "Project Owner",
    projects: "Projects",
    provider: "Provider",
    providers: "Providers",
    purchase: "Purchase",
    purchasing_organization: "Purchase Organization",
    queue: "Queue",
    queues_monitoring: "Queues Monitoring",
    queues: "Queues",
    quick_actions: "Quick actions",
    redo: "Redo",
    register: "Register",
    registration_date: "Registration Date",
    registration: "Registration",
    remaining: "Remaining",
    reports: "Reports",
    requests: "Requests",
    required_field: "This field is required",
    research: "Researcher",
    researcher: "Researcher",
    reset_my_password: "Reset my password",
    reset_password_error_message:
      "The resource requested could not be found on this server.",
    results: "Results",
    resume: "resume",
    retry: "Repeat",
    role_list: "List of roles",
    role_Name: "Role name",
    roles_and_departments: "Roles & Departments",
    roles_and_permissions: "Roles and permissions",
    roles: "Roles",
    rows_per_page: "Rows per Page",
    running: "Running",
    saml_configuration: "Configuration SAML",
    save_changes: "Save Changes",
    save: "To safeguard",
    schedule: "Calendar",
    scheduled_tasks: "Scheduled tasks",
    scheduled_type: "Scheduled Type",
    search: "Search",
    see_everything: "See everything",
    see_more: "See More",
    select_common_fields: "Select the fields to extract from your document",
    select_custom_fields: "Enter custom fields to extract from your document.",
    session_expired_message: "User Session Expired, Please Login again",
    select_role: "Select Role",
    sender_account: "Sender account",
    sender_name: "Sender's name",
    setting: "Parameter",
    sign_in_with_google: "Sign in with Google",
    sign_in_with_sso: "Sign in with SSO",
    sign_up: "sign up",
    sla_prediction: "SLA prediction",
    slack: "Slack",
    smtp_port: "SMTP port",
    smtp_server: "SMTP server",
    staging: "Staging",
    start_at: "Start At",
    start_date: "Start Date",
    start_execution: "Start Execution",
    start_time: "Start Time",
    state: "State",
    status: "Status",
    street: "STREET",
    submit_and_design: "Submit & Design",
    submit_And_train: "Submit & Train",
    submit: "Submit",
    successful: "Successful",
    support: "Support",
    tags: "Tags",
    task_id: "Task Id",
    task_in_progress: "Tasks in progress",
    task_name: "Task Name",
    task_type: "Task Type",
    task: "Task",
    tasks_created: "Tasks created",
    tasks: "Tasks",
    tenant_id: "Tenant ID",
    terminate: "End",
    terminated: "Terminated",
    terms: "I accept the general terms of use",
    thank_you: "Thank you !",
    this_is_your_first_login: "This is your first login ?",
    time_zone: "Time zone",
    time: "Time",
    timed_out: "Timed Out",
    title: "Title",
    to_cancel: "To cancel",
    to_track_and_manage_case_documents_and_other_documents:
      "To follow and find cases and other documents",
    today: "Today",
    total_jobs: "Total Jobs",
    total: "Total",
    training_files: "Training Files",
    transactions: "Transactions",
    trigger_info: "Trigger Information",
    trigger_type: "Trigger Type",
    triggerName: "Trigger Name",
    type: "Type",
    unassigned: "Unassigned",
    undo: "Cancel",
    unique_reference: "Unique reference",
    update_project: "Update Project",
    update_user: "Update user",
    update: "Update",
    upload_new: "Upload New",
    user_overview: "User overview",
    user_profile: "User Profile",
    username: "Username",
    users_and_groups: "Users and Groupo",
    users_list: "Users list",
    users: "Users",
    val_password_validation_error_msg:
      "Your password must be at least 12 characters long.To be on the safe side, try to use upper and lower case letters,as well as numbers and symbols",
    validate_email: "Validate Email",
    validate: "Validate",
    variants: "Variants",
    version: "Version",
    view_and_manage_license_allocation:
      "Show and receive the license allowance.",
    weekly: "Weekly",
    welcome_to_aira: "Welcome to AIRA",
    welcome: "Welcome",
    wf_name: "Workflow Name",
    workflow_execution: "Workflow Execution",
    workflow_id: "Workflow Id",
    workflow_name: "Workflow Name",
    workflow: "Workflow",
    year: "Year",
    your_avatar: "Your Avatar",
    your_email: "Your email",
    your_password: "Your password",
    zoom_in: "Enlarge",
    zoom_out: "Zoom",
    next: "Next",
    form: "Form",
    condition: "Condition",
    actions: "Actions",
    external_applications: "External Applications",
    import_license: "Import License",
  },
  fr: {
    account_number: "Numéro de compte",
    action: "Action",
    general_job: "General Jobs",
    library: "Library",
    activate_all: "Activer",
    activation: "Activation",
    active_users: "Utilisateurs actifs",
    activities: "Activités",
    add_a_new_card: "Ajouter une nouvelle carte",
    add_bot: "Ajouter",
    add_digital_agent: "Agent numérique",
    add_model: "Ajouter le modèle",
    add_new_instance: "Ajouter une nouvelle instance",
    add_new_job: "Ajouter un nouveau flux de travail",
    add_new_form: "Ajouter un nouveau formulaire",
    add_new_model: "Ajouter un nouveau modèle",
    add_or_manage_user_roles_and_departments:
      "Ajouter ou gérer les rôles et départements des utilisateurs",
    add_or_manage_users_and_groups:
      "Ajouter ou gérer les rôles et départements des utilisateurs",
    add_user: "Ajouter l'utilisateur",
    add: "Ajouter",
    address: "Adresse",
    admin_admin: "Administrer",
    administration: "Administration",
    alerts_and_notifications: "Alertes et notifications",
    alerts: "Alertes",
    allow_you_to_authenticate_users_against_an_external_idp_using_the_saml:
      "Vous permettre d'authentifier les utilisateurs contre un PDI externe en utilisant le SAML",
    already_exist: "Ce nom existe déjà",
    another_msg: "Un autre travail est déclencheur pour récupérer",
    app_id: "Identifiant D'application",
    application_name: "Nom de l'application",
    apply: "Appliquer",
    assertion_customer_service: "Affirmation du service client",
    assets: "Actifs",
    assoiciated_queue: "File d'attente assistée",
    auto_layout: "Disposition automatique",
    auto_retry: "Réessayer automatiquement",
    avatar_helps_you_and_your_teammates_recognize_you:
      "Avatar vous aide, vous et vos coéquipiers, vous reconnaissez",
    back_to_models: "Retour aux modèles",
    back_to_projects: "Retour aux projets",
    bill: "Facture",
    bot_name: "Nom de bot",
    bot: "Bot",
    bots: "Bots",
    digital_agent_name: "Nom de l'agent numérique",
    digital_agent: "Agent numérique",
    digital_agents: "Agents numériques",
    buyer_group: "Groupe d'acheteurs",
    cancel: "Annuler",
    card_holder: "Titulaire de la carte",
    case_no: "Cas #",
    case_title: "Titre de cas",
    cases: "Cas",
    casiers: "Casiers",
    center_code: "Code",
    center: "Centre",
    certificate: "Certificat",
    change_your_smtp_settings: "Modifiez vos paramètres SMTP",
    city: "Ville",
    clear: "Clair",
    code: "Code",
    common_fields: "Champs communs",
    communication: "Communication",
    completed: "Complété",
    confirm_password: "Confirmez le mot de passe",
    confirm_your_password: "Confirmer votre mot de passe",
    change_password: "Changer le mot de passe",
    congratulations: "Toutes nos félicitations",
    connection_history: "Historique des connexions",
    connection_type: "Type de connexion",
    connections: "Connexions",
    contact_number: "Numéro de contact",
    cost_center: "Centre de coûts",
    country: "Pays",
    create_a_project: "Créer un projet",
    create_an_invoice: "Créer une facture",
    create_new_instance: "Créer une nouvelle instance",
    create_new_job: "Créer un emploi",
    create_new_project: "Créer un projet",
    create_new_user: "Créer un utilisateur",
    create_version: "Créer une version",
    created_at: "Créé à",
    created_by: "Créé par",
    creation_date: "Date de création",
    creation: "Création",
    credential_request: "Demande d'identification",
    credentials: "Crédits",
    current_user: "Utilisateur actuel",
    custom_fields: "Les champs personnalisés",
    dashboard: "Tableau de bord",
    data_source: "La source de données",
    deactivate_my_account: "Désactivez mon compte",
    delete_all: "Supprimer",
    delete_avatar: "Supprimer l'avatar",
    departments_list: "Liste des départements",
    departments: "Départements",
    description: "Description",
    design: "Conception",
    designation: "Désignation",
    digibot_preview: "Aperçu de Digibot",
    digital_agent_name: "Nom de l'agent numérique",
    directories: "Pertinent",
    disable_all: "Désactiver",
    disableTrigger: "Désactiver le déclencheur à",
    disconnect: "Se déconnecter",
    document_language: "Document Language",
    document_management: "Gestion de documents",
    document_type: "Type de document",
    document: "Document",
    documents: "Documents",
    due_date: "Date d'échéance",
    edit_model: "Modifier le modèle",
    edit_instance: "modifier l'instance",
    edit_profile: "Editer le profil",
    email: "E-mail",
    empower_tomorrow: "Autonomier demain",
    enable_sla_msg: "Activer SLA pour cette file d'attente",
    enable: "Acteur",
    end_time: "Heure de fin",
    enter_email: "Veuillez saisir un e-mail valide",
    enter_full_name: "Veuillez saisir le nom complet",
    enter_role: "Veuillez sélectionner le rôle",
    enter_username: "Saisissez votre nom d'utilisateur",
    enter_your_new_password: "Entrez votre nouveau mot de passe",
    entity_id: "ID de l'entité",
    entity: "Entité",
    events: "Événements",
    excel: "Exceller",
    execute: "Exempter",
    executed_jobs: "Emplois exécutés",
    execution_history: "Historique d'execution",
    execution_id: "ID d'exécution",
    execution_time: "Temps d'exécution",
    execution: "Exécution",
    expires_on: "Expire le",
    failed: "Échoué",
    first_name: "Prénom",
    flight_info: "Informations sur le vol",
    forgot_your_password: "Mot de passe oublié ?",
    github: "Github",
    good_afternoon: "Bon après-midi",
    good_evening: "Bonne soirée",
    good_morning: "Bonjour",
    group_actions: "Actions de groupe",
    groupes_list: "Liste des groupes",
    groupes: "Groupes",
    guest_users: "Utilisateurs invités",
    hello: "Bonjour",
    historical: "Historique",
    history_of_executions: "Histoire des exécutions",
    house: "Num de maison",
    id: "IDENTIFIANT",
    idp_spotted: "IDP repéré",
    idp: "Pilier",
    in_progress: "En cours",
    in_seconds: "(En secondes)",
    inactive: "Inactif",
    inbox: "Boîte de réception",
    instance_name: "Nom de l'instance",
    integration_service: "Service d'entrée",
    internal_order: "Commande interne",
    invoice_name: "Nom de facture",
    job_description: "Description de l'emploi",
    job_list: "Liste d'emplois",
    job_name: "Nom du travail",
    job_status: "Statut d'emploi",
    job_version: "Version de l'emploi",
    job: "Flux de travail",
    jobs: "Flux de travail",
    language: "Langue",
    last_name: "Nom de famille",
    last_updated_at: "Dernière mise à jour à",
    learning_instance: "Instance d'apprentissage",
    create_learning_instance: "Créer une instance d'apprentissage",
    licenses: "Licence",
    list_of_bots: "Liste de robots",
    login_success_message: "Vous vous êtes connecté avec succès !",
    login_history: "Historique de connexion",
    login_url: "URL de connexion",
    login: "Connexion",
    logout_url: "URL de connexion",
    logs: "Bûches",
    real_time_logs: "Journaux en temps réel",
    manager: "Directeur",
    mandatory: "obligatoire",
    max_item_msg:
      "Maximum des travaux en attente et en cours d'exécution autorisés simultanément",
    max_of_retry: "Max de la réessayer",
    means_of_payments: "Moyens de paiement",
    members: "Membres",
    min_item_msg: "Minimum d'articles pour déclencher le premier emploi",
    min_six_char: "Le mot de passe doit contenir un minimum de 6 caractères",
    mission_allocation: "Allocation de mission",
    model_flow: "Flux de modèle",
    model_name: "Nom du modèle",
    models: "Des modèles",
    modified: "Modifié",
    modify_my_profile: "Modifier mon profil",
    month: "Mois",
    monthly_activity_overview: "Aperçu de l'activité mensuelle",
    monthly: "Mensuel",
    my_bots: "Mes bots",
    my_planning: "Ma planification",
    my_robots: "Mes robots",
    name_and_description: "Nom et description",
    name_taken: "Le nom d'utilisateur est pris",
    name: "Nom",
    navigator: "Navigateur",
    need_help: "Besoin d'aide ?",
    new_password: "Nouveau mot de passe",
    no_job_found: "Aucun travail trouvé",
    no_records: "Aucun enregistrement disponible",
    no_space: "Aucun espace autorisé",
    nonWorkingdaysRest: "Restrictions de journées sans travail",
    not_running: "Ne pas courrir",
    notepad: "Bloc-notes",
    nothing_here: "Rien ici",
    notifications: "Notifications",
    ok_understood: "OK compris !",
    optional: "facultatif",
    orchestrator: "Orchestrateur",
    org_name: "Le nom de votre organisation",
    organization: "Organisation",
    other: "Autre",
    owner: "Propriétaire",
    participated: "Participé",
    password_not_match: "Le mot de passe ne correspond pas",
    password_reset_link_msg:
      "Un lien vous a été envoyé par e-mail pour réinitialiser votre mot de passe",
    password: "Mot de passe",
    pause: "Casser",
    paused: "Interrompu",
    payment_methods: "Méthodes de payement",
    pending_tasks: "Tâches en attente",
    permissions: "Autorisation",
    phone: "Téléphone",
    planning: "Planification",
    platform: "Plateforme",
    please_enter_your_email_to_reset_your_password:
      "Veuillez saisir votre e-mail pour réinitialiser votre mot de passe",
    please_select_a_task: "Veuillez sélectionner une tâche",
    post: "Poste",
    previews: "Aperçu",
    priority: "Priorité",
    process_mining: "Procédure d'exploitation",
    process: "Processus",
    processName: "Nom du processus",
    production: "Production",
    profile: "Profil",
    project_desc: "Description du projet",
    project_due_date: "Date d'échéance du projet",
    project_language: "Langue du projet",
    project_name: "nom du projet",
    Project_owner: "Propriétaire du projet",
    projects: "Projets",
    provider: "Fournisseur",
    providers: "Fournisseurs",
    purchase: "Achat",
    purchasing_organization: "Organisation d'achat",
    queue: "File d'attente",
    queues_monitoring: "Surveillance des files d'attente",
    queues: "Files d'attente",
    quick_actions: "Actions rapides",
    redo: "Refaire",
    register: "Enregistrer",
    registration_date: "Date d'inscription",
    registration: "Inscription",
    remaining: "Restant",
    reports: "Rapports",
    requests: "Demandes",
    required_field: "Ce champ est obligatoire",
    research: "Chercheur",
    researcher: "Chercheur",
    reset_my_password: "Réinitialiser mon mot de passe",
    reset_password_error_message:
      "La ressource demandée n'a pas pu être trouvée sur ce serveur.",
    results: "Résultats",
    resume: "CV",
    retry: "Répéter",
    role_list: "Liste des rôles",
    role_Name: "Nom de rôle",
    roles_and_departments: "Rôles et départements",
    roles_and_permissions: "Rôles et autorisations",
    roles: "Les rôles",
    rows_per_page: "Lignes par page",
    running: "En cours",
    saml_configuration: "Configuration SAML",
    save_changes: "Sauvegarder les modifications",
    save: "Pour sauvegarder",
    schedule: "Calendrier",
    scheduled_tasks: "Tâches planifiées",
    scheduled_type: "Type planifié",
    search: "Recherche",
    see_everything: "Tout voir",
    see_more: "Voir plus",
    select_common_fields:
      "Sélectionnez les champs à extraire à partir de votre document",
    select_custom_fields:
      "Entrez les champs personnalisés pour extraire de votre document.",
    session_expired_message:
      "Session utilisateur expirée, veuillez vous reconnecter",
    select_role: "Sélectionner un rôle",
    sender_account: "Compte de l'expéditeur",
    sender_name: "Nom de l'expéditeur",
    setting: "Paramètre",
    sign_in_with_google: "Connectez-vous avec Google",
    sign_in_with_sso: "Connectez-vous avec SSO",
    sign_up: "s'inscrire",
    sla_prediction: "Prédiction SLA",
    slack: "Mou",
    smtp_port: "Port SMTP",
    smtp_server: "Serveur SMTP",
    staging: "Mise en scène",
    start_at: "Commencer à",
    start_date: "Date de début",
    start_execution: "Démarrer l'exécution",
    start_time: "Heure de début",
    state: "État",
    status: "Statut",
    street: "RUE",
    submit_and_design: "Soumettre et concevoir",
    submit_And_train: "Soumettre et se former",
    submit: "Soumettre",
    successful: "Réussi",
    support: "Soutien",
    tags: "Mots clés",
    task_id: "ID de tâche",
    task_in_progress: "Tâches en cours",
    task_name: "Nom de la tâche",
    task_type: "Type de tâche",
    task: "Tâche",
    tasks_created: "Tâches créées",
    tasks: "Tâches",
    tenant_id: "ID du locataire",
    terminate: "Fin",
    terminated: "Terminé",
    terms: "J'accepte les conditions d'utilisation générales",
    thank_you: "Merci !",
    this_is_your_first_login: "Ceci est votre première connexion?",
    time_zone: "Fuseau horaire",
    time: "Temps",
    timed_out: "Fin du temps",
    title: "Titre",
    to_cancel: "Annuler",
    to_track_and_manage_case_documents_and_other_documents:
      "Suivre et trouver des cas et autres documents",
    today: "Aujourd'hui",
    total_jobs: "Total des emplois",
    total: "Total",
    training_files: "Dossiers de formation",
    transactions: "Transactions",
    trigger_info: "Déclencher des informations",
    trigger_type: "Type de déclenchement",
    triggerName: "Nom de déclenchement",
    type: "Taper",
    unassigned: "Non attribué",
    undo: "Annuler",
    unique_reference: "Référence unique",
    update_project: "Mettre à jour le projet",
    update_user: "Mettre à jour l'utilisateur",
    update: "Mise à jour",
    upload_new: "Télécharger de nouveau",
    user_overview: "Aperçu de l'utilisateur",
    user_profile: "Profil de l'utilisateur",
    username: "Nom d'utilisateur",
    users_and_groups: "Utilisateurs et groupo",
    users_list: "Liste des utilisateurs",
    users: "Utilisateurs",
    val_password_validation_error_msg: `Votre mot de passe doit comporter au moins 12 caractères.Votre mot de passe doit comporter au moins 12 caractÃƒÂ¨res.\r Pour plus de sÃƒÂ©curitÃƒÂ©, essayez dÃ¢â‚¬â„¢utiliser des minuscules et des majuscules,ainsi que des nombres et des symboles`,
    validate_email: "Email validé",
    validate: "Valider",
    variants: "Variantes",
    version: "Version",
    view_and_manage_license_allocation:
      "Afficher et recevoir l'allocation de licence.",
    weekly: "Hebdomadaire",
    welcome_to_aira: "Bienvenue à Aira",
    welcome: "Accueillir",
    wf_name: "Nom du flux de travail",
    workflow_execution: "Exécution du workflow",
    workflow_id: "ID de flux de travail",
    workflow_name: "Nom du flux de travail",
    workflow: "Flux de travail",
    year: "Année",
    your_avatar: "Votre avatar",
    your_email: "Votre e-mail",
    your_password: "Votre mot de passe",
    zoom_in: "Agrandir",
    zoom_out: "Zoom",
    next: "Suivant",
    form: "Former",
    condition: "Condition",
    actions: "Actions",
    external_applications: "Demandes externes",
    import_licence: "Licence d'importation",
  },
  sp: {
    hello: "Hola",
    quick_actions: "Acciones rápidas",
    transactions: "Actas",
    job: "Trabajo",
    bot: "bot",
    idp: "IDP",
    reports: "Informes",
    timed_out: "Caducado",
    workflow_name: "Nombre de flujo de trabajo",
    workflow_id: "ID de flujo de trabajo",
    real_time_logs: "Registros en tiempo real",
    start_time: "Hora de inicio",
    end_time: "Hora de finalización",
    status: "Estado",
    notifications: "Notificaciones",
    alerts: "Alertas",
    events: "Eventos",
    admin_admin: "Administración",
    edit_profile: "Editar perfil",
    planning: "Planificación",
    start_execution: "Iniciar ejecución",
    status: "Estado",
    owner: "Dueño",
    created_at: "Creado en",
    inbox: "Bandeja de entrada",
    case_no: "No caso",
    case_title: "Título",
    job_name: "Nombre del trabajo",
    application_name: "Nombre de la aplicación",
    status: "Estado",
    current_user: "Usuario actual",
    due_date: "Fecha de vencimiento",
    rows_per_page: "Filas por página",
    participated: "Participado",
    case_no: "No caso",
    case_title: "Título",
    job_name: "Nombre del trabajo",
    application_name: "Nombre de la aplicación",
    status: "Estado",
    current_user: "Usuario actual",
    due_date: "Fecha de vencimiento",
    rows_per_page: "Filas por página",
    unassigned: "No asignado",
    case_no: "No caso",
    case_title: "Título",
    job_name: "Nombre del trabajo",
    application_name: "Nombre de la aplicación",
    due_date: "Fecha de vencimiento",
    action: "Acción",
    rows_per_page: "Filas por página",
    nothing_here: "Nada aquí",
    search: "Buscar",
    bots: "Bots",
    name: "Nombre",
    digital_agent_name: "Nombre del agente digital",
    creation_date: "Fecha de creación",
    status: "Estado",
    action: "Acción",
    rows_per_page: "Filas por página",
    researcher: "Investigador",
    owner: "Dueño",
    created_at: "Creado en",
    researcher: "Investigador",
    rows_per_page: "Filas por página",
    dashboard: "Panel",
    process_mining: "Minería de procesos",
    cases: "Casos",
    events: "Eventos",
    activities: "Actividades",
    variants: "Variantes",
    events: "Eventos",
    april: "Descripción del trabajo",
    may: "Puede",
    june: "Junio",
    july: "Julio",
    august: "Agosto",
    september: "Septiembre",
    october: "Octubre",
    november: "Noviembre",
    december: "Diciembre",
    add_new_model: "Agregar nuevo modelo",
    type: "Tipo",
    owner: "Dueño",
    created_at: "Creado en",
    researcher: "Investigador",
    learning_instance: "Instancia de aprendizaje",
    add_new_instance: "Agregar nueva instancia",
    status: "Estado",
    language: "Idioma",
    researcher: "Investigador",
    workflow_execution: "Ejecución del flujo de trabajo",
    execution: "Ejecución",
    workflow_name: "Nombre de flujo de trabajo",
    workflow_id: "ID de flujo de trabajo",
    start_time: "Hora de inicio",
    end_time: "Hora de finalización",
    status: "Estado",
    rows_per_page: "Filas por página",
    task_name: "Nombre de la tarea",
    wf_name: "Nombre de WF",
    task_id: "ID de tarea",
    task_type: "Tipo de tarea",
    execution_time: "Tiempo de ejecución",
    status: "Estado",
    rows_per_page: "Filas por página",
    queues_monitoring: "Monitoreo de colas",
    queues: "Colas",
    please_select_a_task: "Seleccione una tarea",
    bots: "Bots",
    job_status: "Estado de trabajo",
    transactions: "Actas",
    year: "Año",
    month: "Mes",
    administration: "Administración",
    users_and_groups: "Usuarios y grupos",
    add_or_manage_users_and_groups: "Agregar o administrar usuarios y grupos",
    users_and_groups: "Usuarios y grupos",
    email: "Correo electrónico",
    designation: "Designación",
    action: "Acción",
    rows_per_page: "Filas por página",
    created_by: "Creado por",
    created_at: "Creado en",
    action: "Acción",
    rows_per_page: "Filas por página",
    researcher: "Investigador",
    roles_and_departments: "Roles y departamentos",
    add_or_manage_user_roles_and_departments:
      "Agregar o administrar roles de usuario y departamentos",
    description: "Descripción",
    created_at: "Creado en",
    action: "Acción",
    rows_per_page: "Filas por página",
    manager: "Gerente",
    created_at: "Creado en",
    action: "Acción",
    rows_per_page: "Filas por página",
    researcher: "Investigador",
    saml_configuration: "Configuración SAML",
    allow_you_to_authenticate_users_against_an_external_idp_using_the_saml:
      "Permitirle autenticar a los usuarios con un IDP externo utilizando el SAML",
    saml_configuration: "Configuración SAML",
    enable: "Permitir",
    provider: "Proveedor",
    entity_id: "ID de entidad",
    assertion_customer_service: "Aserción de servicio al cliente",
    tenant_id: "ID de inquilino",
    app_id: "ID de aplicación",
    login_url: "URL de inicio de sesión",
    logout_url: "URL de cierre de sesión",
    certificate: "Certificado",
    submit: "Entregar",
    document_management: "Gestión de documentos",
    to_track_and_manage_case_documents_and_other_documents:
      "Para rastrear y administrar documentos de casos y otros documentos",
    document_management: "Gestión de documentos",
    directories: "Directorios",
    documents: "Documentos",
    name: "Nombre",
    type: "Tipo",
    modified: "Modificado",
    action: "Acción",
    licenses: "Licencias",
    view_and_manage_license_allocation:
      "Ver y administrar la asignación de licencias.",
    integration_service: "Servicio de integración",
    execute: "Ejecutar",
    save: "Ahorrar",
    schedule: "Cronograma",
    zoom_in: "Acercarse",
    setting: "Configuración",
    zoom_in: "Acercarse",
    zoom_out: "Disminuir el zoom",
    create_version: "Crear versión",
    undo: "Deshacer",
    redo: "Rehacer",
    auto_layout: "Diseño automático",
    version: "Versión",
    workflow: "Flujo de trabajo",
    execution_history: "Historial de ejecución",
    logs: "Registro",
    running: "Correr",
    completed: "Terminado",
    paused: "Detenido",
    failed: "Fallido",
    terminated: "Terminado",
    not_running: "No corras",
    terminate: "Terminar",
    pause: "Pausa",
    resume: "Reanudar",
    retry: "Rever",
  },
  gr: {
    hello: "Hallo",
    quick_actions: "Schnelle Aktionen",
    transactions: "Transaktionen",
    job: "Arbeit",
    real_time_logs: "Echtzeitprotokolle",
    bot: "Bot",
    idp: "Idp",
    reports: "Berichte",
    timed_out: "Zeitlich abgestimmt",
    workflow_name: "Workflow -Name",
    workflow_id: "Workflow -ID",
    start_time: "Startzeit",
    end_time: "Endzeit",
    status: "Status",
    notifications: "Benachrichtigungen",
    alerts: "Warnungen",
    events: "Veranstaltungen",
    admin_admin: "Administrator",
    edit_profile: "Profil bearbeiten",
    planning: "Planung",
    start_execution: "Ausführung starten",
    status: "Status",
    owner: "Eigentümer",
    created_at: "Hergestellt in",
    inbox: "Posteingang",
    case_no: "Fall Nr",
    case_title: "Falltitel",
    job_name: "Berufsbezeichnung",
    application_name: "Anwendungsname",
    status: "Status",
    current_user: "Aktueller Benutzer",
    due_date: "Fälligkeitsdatum",
    rows_per_page: "Zeilen pro Seite",
    participated: "Teilgenommen",
    case_no: "Fall Nr",
    case_title: "Falltitel",
    job_name: "Berufsbezeichnung",
    application_name: "Anwendungsname",
    status: "Status",
    current_user: "Aktueller Benutzer",
    due_date: "Fälligkeitsdatum",
    rows_per_page: "Zeilen pro Seite",
    unassigned: "Nicht zugewiesen",
    case_no: "Fall Nr",
    case_title: "Falltitel",
    job_name: "Berufsbezeichnung",
    application_name: "Anwendungsname",
    due_date: "Fälligkeitsdatum",
    action: "Aktion",
    rows_per_page: "Zeilen pro Seite",
    nothing_here: "Nichts hier",
    search: "Suchen",
    bots: "Bots",
    name: "Name",
    digital_agent_name: "Name des digitalen Agenten",
    creation_date: "Erstellungsdatum",
    status: "Status",
    action: "Aktion",
    rows_per_page: "Zeilen pro Seite",
    researcher: "Forscher",
    owner: "Eigentümer",
    created_at: "Hergestellt in",
    researcher: "Forscher",
    rows_per_page: "Zeilen pro Seite",
    dashboard: "Armaturenbrett",
    process_mining: "Prozessabbau",
    cases: "Fälle",
    events: "Veranstaltungen",
    activities: "Aktivitäten",
    variants: "Varianten",
    events: "Veranstaltungen",
    april: "Arbeitsbeschreibung",
    may: "Mai",
    june: "Juni",
    july: "Juli",
    august: "August",
    september: "September",
    october: "Oktober",
    november: "November",
    december: "Dezember",
    add_new_model: "Neues Modell hinzufügen",
    type: "Typ",
    owner: "Eigentümer",
    created_at: "Hergestellt in",
    researcher: "Forscher",
    learning_instance: "Lerninstanz",
    add_new_instance: "Neue Instanz hinzufügen",
    status: "Status",
    language: "Sprache",
    researcher: "Forscher",
    workflow_execution: "Workflow -Ausführung",
    execution: "Ausführung",
    workflow_name: "Workflow -Name",
    workflow_id: "Workflow -ID",
    start_time: "Startzeit",
    end_time: "Endzeit",
    status: "Status",
    rows_per_page: "Zeilen pro Seite",
    task_name: "Aufgabennname",
    wf_name: "WF -Name",
    task_id: "Aufgaben -ID",
    task_type: "Aufgabentyp",
    execution_time: "Ausführungszeit",
    status: "Status",
    rows_per_page: "Zeilen pro Seite",
    queues_monitoring: "Warteschlangenüberwachung",
    queues: "Warteschlangen",
    please_select_a_task: "Bitte wählen Sie eine Aufgabe aus",
    bots: "Bots",
    job_status: "Beruflicher Status",
    transactions: "Transaktionen",
    year: "Jahr",
    month: "Monat",
    administration: "Verwaltung",
    users_and_groups: "Benutzer & Gruppen",
    add_or_manage_users_and_groups:
      "Benutzer und Gruppen hinzufügen oder verwalten",
    users_and_groups: "Benutzer & Gruppen",
    email: "Email",
    designation: "Bezeichnung",
    action: "Aktion",
    rows_per_page: "Zeilen pro Seite",
    created_by: "Erstellt von",
    created_at: "Hergestellt in",
    action: "Aktion",
    rows_per_page: "Zeilen pro Seite",
    researcher: "Forscher",
    roles_and_departments: "Rollen und Abteilungen",
    add_or_manage_user_roles_and_departments:
      "Benutzerrollen und Abteilungen hinzufügen oder verwalten",
    roles_and_departments: "Rollen und Abteilungen",
    description: "Beschreibung",
    created_at: "Hergestellt in",
    action: "Aktion",
    rows_per_page: "Zeilen pro Seite",
    manager: "Manager",
    created_at: "Hergestellt in",
    action: "Aktion",
    rows_per_page: "Zeilen pro Seite",
    researcher: "Forscher",
    saml_configuration: "SAML -Konfiguration",
    allow_you_to_authenticate_users_against_an_external_idp_using_the_saml:
      "Ermöglichen Sie, dass Sie Benutzer mit dem SAML mit einem externen IDP authentifizieren",
    saml_configuration: "SAML -Konfiguration",
    enable: "Aktivieren",
    provider: "Anbieter",
    entity_id: "Entity ID",
    assertion_customer_service: "Assertion -Kundendienst",
    tenant_id: "Mieter -ID",
    app_id: "App ID",
    login_url: "URL anmelden",
    logout_url: "Abmelde -URL",
    certificate: "Zertifikat",
    submit: "Einreichen",
    document_management: "Dokumenten-Management",
    to_track_and_manage_case_documents_and_other_documents:
      "Falldokumente und andere Dokumente verfolgen und verwalten",
    document_management: "Dokumenten-Management",
    directories: "Verzeichnisse",
    documents: "Unterlagen",
    name: "Name",
    type: "Typ",
    modified: "Geändert",
    action: "Aktion",
    licenses: "Lizenzen",
    view_and_manage_license_allocation:
      "Lizenzallokation anzeigen und verwalten.",
    integration_service: "Integrationsdienst",
    execute: "Ausführen",
    save: "Speichern",
    schedule: "Zeitplan",
    zoom_in: "Hineinzoomen",
    setting: "Einstellung",
    zoom_in: "Hineinzoomen",
    zoom_out: "Rauszoomen",
    create_version: "Version erstellen",
    undo: "Rückgängig machen",
    redo: "Wiederholen",
    auto_layout: "Auto -Layout",
    version: "Ausführung",
    workflow: "Arbeitsablauf",
    execution_history: "Ausführungsgeschichte",
    logs: "Protokolle",
    running: "Läuft",
    completed: "Vollendet",
    paused: "Blättern",
    failed: "Fehlgeschlagen",
    terminated: "Beendet",
    not_running: "Nicht laufen",
    terminate: "Beenden",
    pause: "Pause",
    resume: "Wieder aufnehmen",
    retry: "Wiederholen",
  },
});
export default Strings;
