//import "./wdyr";
import "@fontsource/inter";
import "@fontsource/poppins";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "allotment/dist/style.css";
import { ArcElement, Chart } from "chart.js";
import "dotenv/config";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import "reactflow/dist/style.css";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import App from "./App";
import { GlobalState } from "./hooks/globalState";
import { ErrorBoundary } from "react-error-boundary";
import "./index.css";
import store from "./redux/store";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import ESChatBot from "src/views/chatbot/ESChatBot";

require("dotenv").config();

/* Have To Do Something To Apply This Scrollbar Without Affecting Scroll To Top , See styles.css for webkit scrollbar styles */
/* <Scrollbars style={{ height: "100vh" }}> </Scrollbars>*/

const queryClient = new QueryClient();
Chart.register(ArcElement);

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <ErrorBoundary fallback={<div>Something went wrong</div>}>
      <GlobalState>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Provider store={store}>
            <QueryClientProvider client={queryClient}>
              <App />
              {/* <ReactQueryDevtools initialIsOpen={false} /> */}
              {/* <ReactQueryDevtools initialIsOpen={false} /> */}
            </QueryClientProvider>
          </Provider>
        </LocalizationProvider>
      </GlobalState>
    </ErrorBoundary>
  </React.StrictMode>
);
