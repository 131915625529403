import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Collapse, Grid, IconButton, Typography } from "@mui/material";
import axios from "axios";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import "react-activity/dist/library.css";
import { useForm } from "react-hook-form";
import "react-phone-number-input/style.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import * as yup from "yup";
import Images from "../../assets/index";
import {
  GetSAMLStatus,
  SSOLogin,
  SSOSignOn,
  login as loginUrl,
} from "../../network/networks";
import Strings from "../../values/strings";
import { Wrapper } from "../Login/login.styles";
import LoaderSmall from "../../components/Loader/LoaderSmall";
import ScreenLoader from "../../components/Loader/LoaderScreen";

const SigningPage = ({}) => {
  const navigate = useNavigate();
  const [disable, setDisable] = useState(false);
  const [disableSSOLogin, setDisableSSOLogin] = useState(false);
  const dispatch = useDispatch();

  const [visible, setVisible] = useState(false);
  const [showSessionTimeout, setShowSessionTimeout] = useState(false);
  const queryString = window.location.search;
  const [showUserNotFound, setShowUserNotFound] = useState(false);
  const [showUserNotFoundMsg, setShowUserNotFoundMsg] = useState("");

  useEffect(() => {
    if (queryString.includes("responseId")) {
      let qtr = queryString.split("?responseId=");
      console.log("qtr", qtr[1]);
      localStorage.setItem("ssoResponseToken", qtr[1]);
      callSSOLoginAPI(qtr[1]);
    } else if (queryString.includes("error")) {
      let qtr = queryString.split("?error=");
      setShowUserNotFoundMsg(atob(qtr[1]));
      setShowSessionTimeout(false);
      setShowUserNotFound(true);
    } else {
    }
  }, [1]);
  const callSSOLoginAPI = (token) => {
    let data = { responseId: atob(token) };
    axios
      .post(SSOSignOn, data, { skipAuthRefresh: true })
      .then((response) => {
        console.log("response", response.data);
        if (response.status === 200) {
          localStorage.setItem("signinwith", "SAML");
          localStorage.setItem("isSessionTimeOut", false);
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("allData", JSON.stringify(response.data));
          setShowSessionTimeout(false);
          setVisible(true);
          window.location.assign("/app");
        } else {
          setShowUserNotFoundMsg("Something went wrong");
          setShowSessionTimeout(false);
          setShowUserNotFound(true);
        }
      })
      .catch((err) => {
        console.log("err", err.response);
        setShowUserNotFoundMsg(err.response?.data?.error);
        setShowSessionTimeout(false);
        setShowUserNotFound(true);
      });
  };
  return (
    <Wrapper>
      <Grid container p={1} sx={{ maxHeight: "80vh" }}>
        <ScreenLoader
          loading={true}
          showMsg={true}
          msg={"Please be patient while we sign you in to Aira !"}
        />
        <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
          <Grid container pt={8} px={3}>
            <div className="login-form">
              <Grid item xs={12}>
                <Collapse in={showUserNotFound}>
                  <Alert
                    color="error"
                    className="mb-2"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setShowUserNotFound(false);
                        }}
                      >
                        x
                      </IconButton>
                    }
                  >
                    {showUserNotFoundMsg}
                  </Alert>
                </Collapse>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default SigningPage;
