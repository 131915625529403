import ReactDOM from "react-dom";
import { ReactComponent } from "react-formio";
import StepLayout from "./StepLayout";
import settingsForm from "./setting";

const MultiStepFormComponent = (props) => {
  console.log("props", props);
  const onSubmitMultiStep = (data) => {
    props.onChange(data);
  };
  return (
    <>
      <StepLayout onSubmitMultiStep={onSubmitMultiStep} prop={props} />
    </>
  );
};

export default class MultiStepFormComponentField extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation:  string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: "MultiStepForm Component",
      icon: "square",
      group: "Data",
      documentation: "",
      weight: -10,
      schema: MultiStepFormComponentField.schema(),
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   * @param component
   * @param options
   * @param data
   * @param sources
   * @returns {*}
   */
  init() {
    super.init();
  }
  get inputInfo() {
    const info = super.inputInfo;
    return info;
  }
  static schema() {
    return ReactComponent.schema({
      type: "MultiStepFormComponent",
      label: "MultiStepFormComponent",
      key: "MultiStepForm",
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    return ReactDOM.render(
      <MultiStepFormComponent
        component={this.component}
        value={this.dataValue} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.
      />,
      element
    );
  }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
}
