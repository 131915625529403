import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import { Grid, IconButton } from "@mui/material";
import Stack from "@mui/material/Stack";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Images from "../../assets/index";
import "./styles.scss";

import { ThreeDots } from "react-loader-spinner";
import { toggleMode } from "../../redux/slices/themeSlice";
import Colors from "../../values/colors";
import LanguageSelector from "../LanguageSelector/LanguageSelector";

const LoginHeader = () => {
  const dispatch = useDispatch();
  const isDarkMode = useSelector((state) => state.theme.isDarkMode, _.isEqual);
  const assets = useSelector((state) => state.assets.assets, _.isEqual);

  const [logo, setLogo] = useState("");
  const [loadingLogo, setLoadingLogo] = useState(false);

  useEffect(() => {
    setLoadingLogo(true);
    let _logo = isDarkMode
      ? assets?.logo_dark_img ?? Images.logo_dark
      : assets?.logo_light_img ?? Images.logo;
    setLogo(_logo);
    setTimeout(() => {
      setLoadingLogo(false);
    }, 1000);
  }, [isDarkMode, assets]);

  return (
    <Grid container px={3} pt={3} justifyContent={"space-between"}>
      <Grid item xs={6} px={3}>
        {loadingLogo ? (
          <ThreeDots
            height="50"
            width="50"
            radius="9"
            color={Colors.airaPrimary}
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        ) : (
          <img src={logo} alt="Logo" className="logo" />
        )}
      </Grid>
      <Grid item>
        <Stack direction="row" spacing={1}>
          <IconButton
            onClick={() => {
              dispatch(toggleMode("login"));
            }}
          >
            {isDarkMode ? <LightModeIcon /> : <DarkModeIcon />}
          </IconButton>
          <LanguageSelector type={"login"} />
        </Stack>
      </Grid>
    </Grid>
  );
};
export default LoginHeader;
