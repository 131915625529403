import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getUploadedAssets } from "../../network/networks";
import _ from "lodash";
const assetsSlice = createSlice({
  name: "assets",
  initialState: {
    title: "AIRA",
    logo_light_img: "",
    logo_dark_img: "",
    favicon_img: "",
    monogram_img: "",
  },
  reducers: {
    setInitialMode: (state, action) => {
      return {
        title: action.payload.title,
        logo_light_img: action.payload.logo_light_img,
        logo_dark_img: action.payload.logo_dark_img,
        favicon_img: action.payload.favicon_img,
        monogram_img: action.payload.monogram_img,
      };
    },
    setAssets: (state, action) => {
      return { assets: action.payload };
    },
  },
});

const { reducer, actions } = assetsSlice;
export const { setInitialMode, setAssets, getAssets } = actions;
export default reducer;
