// export const domainUrl = `https://aira.technology/`;
// export const domainUrl = `https://trial.aira.technology/`;
export const domainUrl = `${window.location.origin}/`;
// export const domainUrl = process.env.REACT_APP_DOMAIN_URL;

// export const domainUrl = `http://192.168.0.33:3333/`; //Shivam ji
// export const domainUrl = `http://192.168.0.26:3333/`; //WAsim ji
// export const domainUrl = `http://192.168.0.31:3333/`; // Ajay ji soni ( POC Expert )
// export const domainUrl = `http://192.168.0.32:3333/`;
// export const domainUrl = `http://192.168.0.31:3333/`;

export const baseurl = `${domainUrl}api/v1/`;
export const IDPFileBaseurl = `${domainUrl}assets/`;
export const elisResponsePath = `${domainUrl}app/loading`;
export const botExePath = `${domainUrl}AIRA-0.1.1.exe`;
export const folder_path = "/opt/core/core/";

// Getting full path of uploaded image
export const getImagePath = (img_name) =>
  `${domainUrl}assets/storage/input/${img_name}`;
export const getProfileImagePath = (user_id) =>
  `${domainUrl}assets/storage/assets/profile_images/profile_img_${user_id}.jpg?timestamp=${new Date().getTime()}`;

//SAles Force Redirect URL
export const SALES_FORCE_REDIRECT_URI = `${domainUrl}salesForceconnectionLoader`;

//ProcessMaker
export const pmBaseURL = `http://internal.pmconsulting.co.in/workflow/`;
export const pmServerURL = `http://internal.pmconsulting.co.in/`;
export const pmWorkspace = `workflow`;

//MlFlowAPI
export const getModelsList = `${baseurl}mlflow/registered-models/list`;
export const createModel = `${baseurl}mlflow/registered-models/create`;
export const getModelVersions = `${baseurl}mlflow/model-versions/search`;

//MlFlowAPI

//AirFrance POC
export const getLuggageTimeline = `${baseurl}device/luggagetimeline`; // get param barcode=987654321'
//AirFrance POC

//Mailchimp
export const getMailChipAuthURL = `${baseurl}connection/getmailchimpurl`;
export const getMCAudienceList = `${baseurl}mailchimp/audience/list`;
export const getMCSegmentList = `${baseurl}mailchimp/segment/list`;
export const getMCEmailTemplates = `${baseurl}mailchimp/email/templates`;
export const getMCFolderList = `${baseurl}mailchimp/folders`;
export const getMCCampaignList = `${baseurl}mailchimp/campaigns/list`;
export const getMCCampaignDetail = `${baseurl}mailchimp/campaigns`;
export const getMCTemplateEditableField = `${baseurl}mailchimp/editable/fields`;

//smtp
export const mailSendSMTP = `${baseurl}connection/mail_send`;
export const createSMTPConnection = `${baseurl}connection/smtp`;
export const updateSMTPConnection = `${baseurl}connection/updateSmtpConnection`; //
//smtp

// Job Library
export const createJobFromLibrary = `${baseurl}library/libtojob`;

// Job router Params AJay soni 07/Dec/20
export const setJobRouterParams = `${baseurl}job/job_router_params`;
export const getJobRouterParams = `${baseurl}job/get_job_activity_params`;
export const jobListWithSearch = `${baseurl}orchestrator/workflow/search`;
export const jobStatusCount = `${baseurl}orchestrator/workflow/status/count`;
export const jobDetails = `${baseurl}orchestrator/workflow/`;
export const jobRegister = `${baseurl}workflow/job_register`;

//Import Export Job
export const exportJob = (job_id) => `${baseurl}job/${job_id}/export`;
export const importJob = `${baseurl}job/import`;

//Cases
export const getCases = `${baseurl}cases`; //?Status= UNASSIGNER/INBOX
export const claimCase = `${baseurl}cases/claim`; // "case_id" : 353
export const getCasesHistoryList = `${baseurl}cases/case_history_list`;
export const getCasesHistoryDetail = `${baseurl}cases/case_history`; //case_id
export const getCasesActivityTaskDetail = `${baseurl}cases/task_history`; //task_id

export const getCasesFormList = `${baseurl}cases/form_list`; //form_list/job_activity_id
export const getCasesVariable = (execID) =>
  `${baseurl}job/execution/${execID}/variables`;
export const getCasesFormContent = `${baseurl}cases/get_form_content`; // form/content/form_id
export const casesFormActivitySubmit = `${baseurl}form_activity/submit`;
export const builderFormUrl = (jobID, selectedFormID) =>
  `${baseurl}job/${jobID}/${selectedFormID}`;

export const login = `${baseurl}auth/login`;
export const SSOLogin = `${baseurl}saml/sso/login`;
export const GetSSOTokenDetails = `${baseurl}saml/azure/sso/token`;
export const SSOSignOn = `${baseurl}saml/sso/signon`;
export const SSOLogout = `${baseurl}saml/sso/logout`;
export const SSOUserSync = `${baseurl}users/sso/sync/delta`;

export const signup = `${baseurl}auth/signup`;
export const verifyemail = `${baseurl}auth/verifyemail`;
export const forgotpassword = `${baseurl}auth/forgotpassword`;
export const resetpassword = `${baseurl}auth/resetpassword`;
export const gettimezones = `${baseurl}auth/gettimezones`;
export const grtpermissions = `${baseurl}permission/list`;
export const notifications = `${baseurl}notifications/list`;

export const subscribe_pns = `${baseurl}user/subscribe_pns`;
export const getuser = `${baseurl}user/list?`;
export const getUserAll = `${baseurl}user/listALL`;

export const getmanagers = `${baseurl}department/getmanagers`;

export const grouplist = `${baseurl}group/list?`;
export const groupAllList = `${baseurl}group/listALL`;
export const groupUsers = `${baseurl}group/groupuser/`;

export const departmentlist = `${baseurl}department/list?`;
export const rolelist = `${baseurl}role/list?`;

export const uploadPythonFile = `${baseurl}bot/uploadpythonFile`;
export const queueSend = `${baseurl}queue/send`;

export const getuserdetails = `${baseurl}user/profile`;
export const createuser = `${baseurl}user/create`;
export const updateuser = `${baseurl}user/update/`;
export const getuserbyid = `${baseurl}user/get/`;
export const deleteuserbyid = `${baseurl}user/delete/`;
export const changeuserstatus = `${baseurl}user/changestatus`;
export const deleteuser = `${baseurl}user/delete`;

export const getroledetails = `${baseurl}role/profile`;
export const createrole = `${baseurl}role/create`;
export const updaterole = `${baseurl}role/update/`;
export const getrolebyid = `${baseurl}role/get/`;
export const deleterolebyid = `${baseurl}role/delete/`;
export const changerolestatus = `${baseurl}role/changestatus`;
export const deleterole = `${baseurl}role/delete`;

export const projectList = `${baseurl}project/list?`;
export const createproject = `${baseurl}project/create`;
export const getprojectbyid = `${baseurl}project/get/`;
export const updateproject = `${baseurl}project/update/`;
export const changeprojectstatus = `${baseurl}project/changestatus`;
export const deleteproject = `${baseurl}project/delete`;
export const getgroupdetails = `${baseurl}group/profile`;
export const creategroup = `${baseurl}group/create`;
export const updategroup = `${baseurl}group/update/`;
export const getgroupbyid = `${baseurl}group/get/`;
export const deletegroupbyid = `${baseurl}group/delete/`;
export const changegroupstatus = `${baseurl}group/changestatus`;
export const deletegroup = `${baseurl}group/delete`;

export const getdepartmentdetails = `${baseurl}department/profile`;
export const createdepartment = `${baseurl}department/create`;
export const updatedepartment = `${baseurl}department/update/`;
export const getdepartmentbyid = `${baseurl}department/get/`;
export const deletedepartmentbyid = `${baseurl}department/delete/`;
export const changedepartmentstatus = `${baseurl}department/changestatus`;
export const deletedepartment = `${baseurl}department/delete`;

export const checkIfJobNameExists = `${baseurl}job/isJobExists`; // get => name:job_name
export const joblist = `${baseurl}job/list?`;
export const createjob = `${baseurl}job/create`;
export const getjobbyid = `${baseurl}job/get/`;
export const updatejob = `${baseurl}job/updatejob/`;
export const changejobstatus = `${baseurl}job/changestatus`;
export const deletejob = `${baseurl}job/delete`; //job/delete/:job_id
export const getJobDetails = (job_id) => `${baseurl}job/${job_id}`; //job/delete/:job_id

//Conductor API
export const callRegisterWorkflow = `${baseurl}workflow/register`;
//Conductor API

//Workflow Operations
export const callTerminateOperation = `${baseurl}operation/terminate`; // params:"job_id":"2733"
export const callRetryOperation = `${baseurl}operation/retry`; // params:"job_id":"2733"
export const callResumeOperation = `${baseurl}operation/resume`; // params:"job_id":"2733"
export const callPauseOperation = `${baseurl}operation/pause`; // params:"job_id":"2733"
export const getWorkflowStatus = `${baseurl}operation/realStatus`; // params:"job_id":"2733"

//Connections API

export const checkIfConnectionNameExists = `${baseurl}connection/isnameexist`; // type:connection_type, name
export const getConnectionEngines = `${baseurl}connection/listengines`;
export const getEngineFormFields = `${baseurl}connection/engine/formfields`; // /engine/formfields/<engine code>
export const getConnectionsList = `${baseurl}connection/listall`; //
export const getConnectionsListDatabase = `${baseurl}connection/listdbs`; //
export const connectConnection = `${baseurl}connection/connect`; // { type:"MySQL"}
export const callDeleteConnection = `${baseurl}connection/delete`; // /delete/:id
export const getConnectionTable = (id) =>
  `${baseurl}connection/${id}/gettables`; // /delete/:id
export const getConnectionsAgainstType = `${baseurl}connection/getallconnectionagainstthetype`; // { type:"MySQL"}
export const getConnectionTableFields = (connection_id) =>
  `${baseurl}connection/${connection_id}/gettablefields`; // { type:"MySQL"}
export const executeConnection = (connection_id) =>
  `${baseurl}connection/${connection_id}/execute`; // { type:"MySQL"}
export const createGoogleConnection = `${baseurl}oauth2/token`;
export const createAzureConnection = `${baseurl}connection/createAzureConnection`;
export const getSmtpConnectionData = `${baseurl}connection/getSmtpConnection`; // params: {id:"asdasd"}

export const createSAPConnection = `${baseurl}connection/connect`;
export const createAWSConnection = `${baseurl}connection/createAWSConnection`;

//AWS
export const validateAWSConnection = `${baseurl}msazure/validateAwsKeys`;

/* Google API's */
export const getGoogleFolderList = `${baseurl}google_drive/folderList`;
export const getGoogleNestedFolderList = `${baseurl}google_drive/file_list`;
export const getGoogleSheets = `${baseurl}google_drive/getSheet`; /* "connection": "","spreadsheetId":"" */
export const getColumnRange = `${baseurl}google_drive/getColumnDetails`;

//Google Calenders
export const getCalenderEvents = `${baseurl}google_drive/googleEvent`;
export const getCalenders = `${baseurl}google_drive/googleCalendar`;
/*


//Google Calenders
/* Google API's */

// connection/connec

//Logs API
export const getExecutionHistory = `${baseurl}logger/executionList`; // params:{job_id,page}
export const getExecutionHistoryLogs = `${baseurl}logger/exeHistory`; // params:{job_id,page,executionId}
export const getRealTimeLogs = `${baseurl}logger/readLogger`; // params:{job_id}

//Versions API
export const getVersionsList = `${baseurl}job/listversions`; // url/2763[job ID]
export const createWorkFlowVersion = `${baseurl}job/createversion`; // url/2763[job ID]
export const deleteWorkFlowVersion = `${baseurl}job/deleteversion`; // url/2763[job ID]
export const rollbackWorkFlowVersion = `${baseurl}job/rollback`; // url/2763[job ID]

//Activity Triggers API
export const createActivityTrigger = `${baseurl}trigger/create`;
export const getAllActivityTrigger = `${baseurl}trigger/list`; // list?job_id=3105&limit=10&offset=1&search=
export const updateTriggerPosition = `${baseurl}trigger/update_position`;
export const deleteTrigger = `${baseurl}trigger/delete_trigger`; //delete?trigger_id=TN0IJENLFUO4LK8Q4Z9DOLDMZEISEKYO
export const unlinkTrigger = `${baseurl}trigger/unlink_trigger`; //delete?trigger_id=TN0IJENLFUO4LK8Q4Z9DOLDMZEISEKYO
export const updateTrigger = `${baseurl}trigger/update`; //delete?trigger_id=TN0IJENLFUO4LK8Q4Z9DOLDMZEISEKYO
export const getTriggerByID = `${baseurl}trigger/listbyid`; //listbyid?trigger_id=TN0IJENLFUO4LK8Q4Z9DOLDMZEISEKYO
export const getTriggersByJobID = `${baseurl}trigger/list_trigger`;
export const assignTrigger = `${baseurl}trigger/assign_trigger`;
export const updateTriggerCondition = (job_activity_id) => {
  return `${baseurl}trigger/job_activity/${job_activity_id}/condition`;
};
export const assignFormTrigger = `${baseurl}trigger/form_assign_trigger`;
export const getFormTrigger = `${baseurl}trigger/form_trigger_list`; // list?job_id=3105&limit=10&offset=1&search=
export const unlinkFormTrigger = `${baseurl}trigger/unlink_form_trigger`;
export const updateFormTriggerPosition = `${baseurl}trigger/form_trigger_position`;
export const updateFormTriggerCondition = `${baseurl}trigger/update_form_trigger_condition`;
//Activity Triggers API

//Designer Variables API
export const getVariableDataType = `${baseurl}variables/variables_type_list`;
export const createVariable = `${baseurl}variables/create`;
export const variableListing = `${baseurl}variables/list`;
export const updateVariable = `${baseurl}variables/update`; //update/2 2{variable id}
export const deleteVariable = `${baseurl}variables/delete`; //delete/2 2{variable id}
export const getJobVariables = `${baseurl}job/jobVariabls/`;

// Designer
export const updateActivityLabel = (jobid) =>
  `${baseurl}job/${jobid}/update/activitylabel`;

export const getapplications = (type) =>
  `${baseurl}applications?node_type=${type}`;
export const getactivity = `${baseurl}application/`;
export const getwfvariables = `${baseurl}application/activity/wfvariables`;
export const addactivity = `${baseurl}job/`;
export const getinputs = `${baseurl}application/activity/`;
export const setinputparams = `${baseurl}job/activity/`;
export const getgraphgsondata = `${baseurl}job/`;
export const bots = `${baseurl}application/bot`;
export const deleteactivity = `${baseurl}job/`;
export const updateactivity = `${baseurl}job/`;
export const cleargraph = `${baseurl}job/`;

export const updateAllActivityPosition = (job_id) =>
  `${baseurl}job/${job_id}/update/allactivities`;

export const getjobactivityparams = `${baseurl}job/activity/`;

export const updateActivityFormTitle = (job_id) =>
  `${baseurl}job/${job_id}/update/activityTitle`; //{ "canvas_element_id","title" }

//Update Edges
export const updateEdgesArr = `${baseurl}job/update/`; //"/job/update/2733" 2733 is jobID
/*
Update Node
"canvas_element_id" : "20cc996a-5897-49d5-afb7-9a8361937393", "coordinate_x" : "657.2500", "coordinate_y" : "94.5000"
 */
// export const updateNode = `job/2733/update/activity`;

//
export const generatetextfromcordinate = `${baseurl}`;
export const getinstacedetails = `${baseurl}`;
export const generatetrainingdata = `${baseurl}`;
export const gettextfromcordinates = `${baseurl}`;

export const getBotlist = `${baseurl}bot/list?`;
export const botlist = `${baseurl}bot/list?`;
export const botLink = `${baseurl}bot/link`;
export const botDelink = `${baseurl}bot/delink`;

export const createmodels = `${baseurl}mlflow/registered-models/create`;
export const listmodels = `${baseurl}mlflow/registered-models/list`;
export const deletemodels = `${baseurl}mlflow/registered-models/delete`;
export const updatemodels = `${baseurl}mlflow/registered-models/update`;
export const searchmodels = `${baseurl}mlflow/registered-models/search`;
export const settagmodel = `${baseurl}mlflow/registered-models/settag`;

export const queuelist = `${baseurl}queue/list`;
export const queuecreate = `${baseurl}queue/create`;
export const queueupdate = `${baseurl}queue/update`;
export const queuedelete = `${baseurl}queue/delete`;

// Refresh Token API
export const getRefreshToken = `${baseurl}auth/refresh`; // API Is In Developemet

// Credential Vaults -
export const Casierlist = `${baseurl}bot/list?`;
export const Credentiallist = `${baseurl}bot/list?`;

export const listIdpFieldDataType = `${baseurl}v2/idp/getdatatypes`;
// export const prediction = "idp/model/learninginstance/";

// Web Agent
// If method is post it will create weblink if it's not it'll get..!
export const createOrGetWebLink = `${baseurl}webentry`; // ?job_activity_id=32

export const getFormsList = `${baseurl}form/list`;
export const createAiraForm = `${baseurl}form`;
export const AddFormContent = `${baseurl}form/content`;
export const AssignForm = `${baseurl}form/assign_job_form`;
export const GetJobForm = `${baseurl}form/get_job_form/`;

export const FormActivitySubmit = `${baseurl}form_activity/submit`;
export const uploadFiles = `${baseurl}form/uploadfile`;
export const queryExecute = `${baseurl}form/query_execute`;
// Service Now

export const serviceNowConnection = `${baseurl}service_now/connection`;
export const getServiceNowTable = `${baseurl}service_now/table_list`;
export const getServiceNowRecords = `${baseurl}service_now/record_id`;
export const table_meta_information = `${baseurl}service_now/table_meta_information`;

//output doc apis
export const createDocument = `${baseurl}output-document`;
export const getOutputDocsList = `${baseurl}output-document/list`;
export const AddDocContent = `${baseurl}output-document/savetemplate`;
export const getTemplate = `${baseurl}output-document/template/`;

///service_now/table_meta_information

export const getBagList = `${baseurl}device/bags_list`;
export const LuggageTimeline = `${baseurl}device/luggagetimeline?barcode=`;

// orchestrator
export const getWorkflow = `${baseurl}orchestrator/workflow`;
export const getExecutionWorkflow = `${baseurl}orchestrator/executionworkflow`;
export const getTasks = `${baseurl}orchestrator/tasks`;
export const getExecutionTasks = `${baseurl}orchestrator/executiontasks`;
export const getQueues = `${baseurl}orchestrator/monitorqueue`;
export const getCountAgainstStatus = `${baseurl}orchestrator/getcountagainststatus`;
export const getTaskTransitions = `${baseurl}orchestrator/gettasktransactions`;
export const listGlobalActivityVariable = `${baseurl}variables/list_global_activity_variable`;
export const getworkflowbyid = (jobid) => `${baseurl}job/${jobid}/activities`;
export const getorchestworkflowbyid = (jobid, wf_id) =>
  `${baseurl}orchestrator/activities/${jobid}/${wf_id}`;
export const getOrchesTaskByWFId = (wf_id) =>
  `${baseurl}orchestrator/${wf_id}/tasks`;

export const getTaskDetailsById = (task_ref_id, wf_id) =>
  `${baseurl}orchestrator/task/${task_ref_id}/${wf_id}`;

// Below API can be used for both get and set variable depends on GET POST method
export const casesVariable = (job_id) => `${baseurl}job/${job_id}/variables`;
export const updateCasesVariable = (job_id) =>
  `${baseurl}job/${job_id}/variables`;

// Form triggers
export const CreateFormTrigger = `${baseurl}trigger/create_form_trigger`;

export const GetCaseVariable = (JobID) => {
  return `${baseurl}job/${JobID}/variables`;
};
export const SetCaseVariable = (execID) => {
  return `${baseurl}job/execution/${execID}/setvariables`;
};
export const AssignRules = `${baseurl}job/assignrules`;

// Email template
export const createTemplates = `${baseurl}email_templates`;
export const getTemplates = `${baseurl}email_templates/`;
export const assignTemplates = `${baseurl}email_templates/assign_template`;
export const deleteTemplate = `${baseurl}email_templates/`;
export const updateTemplate = `${baseurl}email_templates/`;
export const getAssignedTemplate = `${baseurl}email_templates/get_assign_template/`;
export const getTemplateDetails = `${baseurl}email_templates/get/`;
export const fileUpload = `${baseurl}extras/file/upload`;
export const GetUploadedFile = `${baseurl}extras/file/`;
export const getFileDetail = (file_id) => `${baseurl}extras/file/${file_id}`;

export const GetAssignRules = `${baseurl}job/assigengroup`;

//Model Flow API
// Naming it ES as it is conflicting with another name createModel (ML flow )
// which is of no use now
export const getJSONFromFile = `${baseurl}extras/fileToJson`;
export const createESModel = `${baseurl}models`; //method POST {"name" : "testtest" }
export const deleteESModel = (model_id) => `${baseurl}model/${model_id}`;
export const deployESModel = (model_id) => `${baseurl}model/${model_id}/deploy`;
export const getESModelDetails = (model_id) =>
  `${baseurl}model/details/${model_id}`;
export const modelTrainingFileUpload = (model_id) =>
  `${baseurl}model/${model_id}/training`; //model/BA0WNS71HLBOBQ9YXJNSIBQVH1C62FHP/training
export const startModelTraining = (model_id) =>
  `${baseurl}model/${model_id}/starttraining`;
export const startModelPrediction = (model_id) =>
  `${baseurl}model/${model_id}/startprediction`;
export const getEDAResponse = (model_id) =>
  `${baseurl}model/${model_id}/edaresponse`;
export const startPredictionByFile = (model_id) =>
  `${baseurl}model/${model_id}/startpredictionusingcsv`;
//  model/SZMNUEL96NRSEY7NY75JJTIUEL4PLNRE/change/column/dtype'
export const changeESModelDataType = (model_id) =>
  `${baseurl}model/${model_id}/change/column/dtype`;

export const getListSampleFiles = `${baseurl}model/listsamplefiles`;

//Model api
export const modelList = `${baseurl}models`;
export const modelDetails = `${baseurl}model/details/`;
export const modelDryRun = `${baseurl}models/dryrun/`;
export const uploadExistingModel = (model_id) =>
  `${baseurl}model/${model_id}/deployprebuildmodel`;

// Check whether model name exists or not
export const checkIfModelNameExists = `${baseurl}models/isexist`;

// SSO Settings
export const GetSSOSetting = `${baseurl}saml/`;
export const ManageSSOSetting = `${baseurl}saml/manage`;
export const GetProviders = `${baseurl}saml/providers`;
export const GetSAMLStatus = `${baseurl}saml/status`;
export const GetActiveProvider = `${baseurl}saml/active`;

// DMS
export const GetDMSList = `${baseurl}dms/list`;
export const CreateDMSFolder = `${baseurl}dms/createFolder`;
export const AddFileToFolder = `${baseurl}dms/addFilesToDirectory`;
export const renameFileOrFolder = `${baseurl}dms/rename`;
export const searchFileOrFolder = `${baseurl}dms/search`;
export const deleteFileOrFolder = `${baseurl}dms/delete`;

export const checkVariableExist = `${baseurl}job/isExists/`;

export const modelReadFile = `${baseurl}readfile/`;
export const modelSaveContent = `${baseurl}savecontent/`;

// elis
export const elisCostCenter = `${baseurl}elis/costcenter`;
export const elisGroup = `${baseurl}elis/group`;
export const elisOrganization = `${baseurl}elis/organization`;
export const elisInternOrder = `${baseurl}elis/orderintern`;
export const elisCenter = `${baseurl}elis/center`;
export const elisCenterDetails = `${baseurl}elis/centerProviderDetails`;
export const elisAddress = `${baseurl}elis/address`;
export const elisInmac = `${baseurl}elis/inmac/`;
export const elisInmacResponse = `${baseurl}elis/poresponse/`;
export const elisProviders = `${baseurl}elis/providers`;
export const elisProviderDetails = `${baseurl}elis/providerDetails`;

export const jobSchedule = `${baseurl}job/schedule`;
export const jobScheduleDetails = `${baseurl}job/schedule/`;

export const webentryGetFormContent = `${baseurl}webentry/getform?code=`;
export const webentryGetCaseDetails = (execID) =>
  `${baseurl}webentry/getcasedetails?execution_id=${execID}`;

/// Masters
export const appLanguages = `${baseurl}auth/getlanguages`;
export const appCountries = `${baseurl}auth/getcountries`;
export const appChangeTheme = `${baseurl}user/changetheme`;
export const appChangeLanguage = `${baseurl}user/changelanguage `;

//Reports
export const airaReportTable = `${baseurl}dashboard/report/tables`;
export const airaReportTableColumns = (id) =>
  `${baseurl}dashboard/report/${id}/columns`;
export const reportChartTypes = `${baseurl}reports/chart/types`;
export const reportChartSubTypes = (chartId) =>
  `${baseurl}reports/chart/type/${chartId}/subtypes`;
export const reportCreateChart = `${baseurl}reports/create/chart`;
export const updateChartByID = (chartId) =>
  `${baseurl}dashboard/report/chart/${chartId}`;
export const updateChartSettingsByID = (chartId) =>
  `${baseurl}dashboard/report/${chartId}/settings`;
//cnh
export const getCNHReports = `${baseurl}reports/cnh`;
export const getYarnCapacity = `${baseurl}reports/yardcapacity`;
export const getHistory = `${baseurl}reports/history`;
export const getallowedmodules = `${baseurl}user/getallowedmodules`;

export const deleteFile = (file_id) => `${baseurl}extras/file/${file_id}`;
export const updateProfile = (user_id) => `${baseurl}user/updateProfile`;
export const esUpdateProfile = `${baseurl}user/updateProfile`;
export const changePassword = `${baseurl}user/change/password`;

export const deleteIDPFile = (li, job, file) =>
  `${baseurl}v2/idp/li/files/${li}/${job}/${file}`;

export const importLicenseApi = `${baseurl}license/import`;
export const licenseDetails = `${baseurl}license/details`;
export const validateLicense = `${baseurl}auth/validatelicense`;

// IDP V2 Apis
export const createidplearninginstance = `${baseurl}v2/idp/create/learninginstance/`;
export const checkExistingInstanceName = `${baseurl}v2/idp/learninginstance/isexist`;
export const listidplearninginstance = `${baseurl}v2/idp/learninginstance/list`;
export const getInstanceDetails = `${baseurl}v2/idp/getlearninginstancebyid/`;
export const listIdpDocType = `${baseurl}v2/idp/getdocumenttypes`;
export const listIdpFieldsByDocType = (doc_id) =>
  `${baseurl}v2/idp/document/getfields/${doc_id}`;
export const AddIdpFieldsByDocType = (li_id) =>
  `${baseurl}v2/idp/learninginstance/${li_id}/update`;
export const idpPrediction = (li_id) =>
  `${baseurl}v2/idp/learninginstance/${li_id}/prediction`;
export const idpLIDelete = (li_id) =>
  `${baseurl}v2/idp/learninginstance/${li_id}/delete`;
export const getrowdata = `${baseurl}v2/idp/getrowdata`;
export const imageprocessing = (
  li_id
) => `${baseurl}v2/idp/learninginstance/${li_id}/imageprocessing
`;
export const getRegex = `${baseurl}v2/idp/getLearningInstanceRule`;
export const getRegexData = `${baseurl}v2/idp/searchreg`;
export const tableDetection = `${baseurl}v2/idp/li/table/detection`;
export const tableExtraction = `${baseurl}v2/idp/li/table/extraction`;
export const downloadTableExtracted = `${baseurl}v2/idp/li/table/extraction/download`;

export const deployLi = (li_id) => `${baseurl}v2/idp/li/deploy/${li_id}`;
export const getUploadedImage = (li_id) => `${baseurl}v2/idp/getLIfiles/${li_id}
`;
export const addFieldCustomRules = `${baseurl}v2/idp/create/rules`;
export const saveTableExtracted = `${baseurl}v2/idp/li/add/table`;
export const getSavedTable = `${baseurl}v2/idp/li/table`;

export const getStartCasesList = `${baseurl}cases/startcases`;
export const registerWorkflow = `${baseurl}workflow/register`; //
export const getCaseDataFromWfId = `${baseurl}cases/case_number?wf_id=`; //?wf_id=""

// System Configuration
export const systemInformation = `${baseurl}extras/systeminformation`;
export const uploadAssets = `${baseurl}extras/uploadassets`;
export const getUploadedAssets = `${baseurl}extras/getassets`;
export const deleteUploadedAssets = `${baseurl}extras/deleteassets`;

//Aira Tables
export const gettabledata = `${baseurl}table/list_table`;
export const deletetable = `${baseurl}table/delete_table/`;
export const importtable = `${baseurl}table/table_schmeaImport`;
export const viewtabledata = `${baseurl}table/single_table/`;
export const viewtableschema = `${baseurl}table/table_schmea/`;
export const importViewAiraTable = `${baseurl}table/csvImport`;
export const createAiraTable = `${baseurl}table/create_table`;
export const getTableList = `${baseurl}table/table_list`;
export const getColumnList = `${baseurl}table/table_list`;

//Report Tables
export const getreporttablelist = `${baseurl}table/reports`;
export const getvariablebyjobid = `${baseurl}table/report/variables/`;
export const createreporttable = `${baseurl}table/report/create`;
export const getgrididlist = `${baseurl}table/report/grid/`;
export const deletereporttable = `${baseurl}table/report/delete/`;
export const checkIfTitleNameExists = `${baseurl}table/report/title/exist`;
export const viewreporttabledata = `${baseurl}table/report/`;
export const importReportTable = `${baseurl}table/report/import/schema`;
export const getColumnsList = `${baseurl}table/report/columns/`;

// create multiple job variable on import json
export const createMultipleJobVariable = `${baseurl}job/exportVariable`;

// Profile Pic Url
export const updateProfilePic = `${baseurl}user/update/profile/image`;
// Report Dashboard
export const listDashboardReports = `${baseurl}dashboard/reports`;
export const createDashboard = `${baseurl}dashboard/report`;
export const getReportDetails = (id) => `${baseurl}dashboard/report/${id}`;
export const deleteDashboard = (id) => `${baseurl}dashboard/report/${id}`;
export const checkDashboardNameExist = `${baseurl}dashboard/report/exist`;
export const createDashboardReport = `${baseurl}dashboard/report`;
export const listReportCategories = `${baseurl}dashboard/report/categories`;
export const getCategoriesById = (id) =>
  `${baseurl}dashboard/report/category/${id}`;
export const deleteCategoriesById = (id) =>
  `${baseurl}dashboard/report/category/${id}`;
export const getReportByDashboardId = (id) =>
  `${baseurl}dashboard/report/${id}`;

export const createCategory = `${baseurl}dashboard/report/category`;
export const runReportSql = `${baseurl}dashboard/report/run`;
export const listReportTables = `${baseurl}dashboard/report/tables`;
export const listReportTableColumns = (id) =>
  `${baseurl}dashboard/report/${id}/columns`;
export const listChart = `${baseurl}dashboard/report/chart`;
export const checkChartNameExist = `${baseurl}dashboard/report/chart/exist`;
export const createChart = `${baseurl}dashboard/report/chart`;

export const executerRawQuery = `${baseurl}dashboard/report/sqlexec`;

export const getFormByJob = `${baseurl}cases/activityform/`;
export const listidpDeployedLearninginstance = `${baseurl}v2/idp/li/deployed/list`;
export const uploadIDPFiles = `${baseurl}v2/idp/li/job/execution`;
export const getUploadIDPFiles = `${baseurl}v2/idp/li/files/`;

//integration_service
export const getISFormFields = (code) => `${baseurl}is/${code}/fields`;
export const updateISFormFields = `${baseurl}is/fields`;
export const updateISStatus = `${baseurl}is/service/status`;

//Record and PlayBack
export const record = `${baseurl}bot/RecorderSignal`;

//Chat bot
export const askChatBot = `${baseurl}extras/chat`;
export const restartChat = `${baseurl}extras/restartchat`;

//SharePoint

export const getSites = `${baseurl}sharepoint/sites`;
export const getdrivesBySite = `${baseurl}sharepoint/site/drives`;
export const getfolderidBydrive = `${baseurl}sharepoint/site/drive/items`;
// AI Model List
export const listAIDeployedModel = `${baseurl}model/dropdown`;
export const listAIDeployedModelParams = (model_id) =>
  `${baseurl}model/${model_id}/inputparam`;
export const sharepointColumnSetting = `${baseurl}sharepoint/column/settings`;
export const sharepointList = `${baseurl}sharepoint/site/lists`;
export const sharepointListItems = `${baseurl}sharepoint/site/list/items`;

// Office 365
export const getSPFolders = `${baseurl}sharepoint/email/folders`;
export const getMSEmails = (page, rowsPerPage) =>
  `${baseurl}outlook/email/list?limit=${rowsPerPage}&page=${page}`;
