import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import _ from "lodash";
import moment from "moment";
import ReactDOM from "react-dom";
import { ReactComponent } from "react-formio";
import settingsForm from "./settings";
const DatePickerComponent = (props) => {
  var todayDate = "29-12-2023";
  if (!_.isNil(props?.value)) {
    todayDate = props?.value.split("-").reverse().join("-");
  }
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DemoContainer components={["DatePicker"]}>
        <DatePicker
          label={props?.component?.label}
          defaultValue={!_.isNil(props?.value) ? moment(todayDate) : moment()}
          disabled={props?.component?.disabled}
          format="DD-MM-YYYY"
          onChange={(newValue) => {
            console.log("newValue", newValue);
            console.log("moment", moment(newValue).format("DD-MM-YYYY"));
            props.onChange(moment(newValue).format("DD-MM-YYYY"));
          }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
};

export default class DatePickerComponentField extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation:  string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: "DatePicker",
      icon: "square",
      group: "Data",
      documentation: "",
      weight: -10,
      schema: DatePickerComponentField.schema(),
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   * @param component
   * @param options
   * @param data
   * @param sources
   * @returns {*}
   */
  init() {
    super.init();
  }
  get inputInfo() {
    const info = super.inputInfo;
    return info;
  }
  static schema() {
    return ReactComponent.schema({
      type: "DatePickerComponent",
      label: "DatePickerComponent",
      key: "datepicker",
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    return ReactDOM.render(
      <DatePickerComponent
        component={this.component} // These are the component settings if you want to use them to render the component.
        value={this.dataValue} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.
      />,
      element
    );
  }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
}
