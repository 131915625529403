import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, TextField, TextFieldProps } from "@mui/material";
import { SxProps } from "@mui/material/styles";
import Strings from "../../values/strings";

type SearchProps = {
  handleSearchEvent: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  sxStyleProps?: SxProps;
  restProps?: TextFieldProps;
  placeholder?: string;
};

const Search = ({
  handleSearchEvent,
  sxStyleProps = {},
  placeholder = Strings.search,
  ...restProps
}: SearchProps) => {
  return (
    <TextField
      size="small"
      sx={{
        fontSize: "14px",
        padding: "5px 10px 5px 20px",
        borderRadius: "30px",
        ".MuiOutlinedInput-root": {
          borderRadius: "30px",
        },
        ...sxStyleProps,
      }}
      fullWidth
      autoFocus
      autoComplete="off"
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      type="text"
      id="search"
      name="search"
      placeholder={placeholder}
      onChange={handleSearchEvent}
      {...restProps}
    />
  );
};

export default Search;
