import CopyrightIcon from "@mui/icons-material/Copyright";
import { Box, Stack, Typography } from "@mui/material";
import "./styles.scss";

const LoginFooter = (props) => {
  return (
    <Box
      sx={{
        textAlign: "right",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        gap={0.5}
        sx={{
          textAlign: "right",
          m: 5,
        }}
      >
        <CopyrightIcon
          sx={{
            fontSize:"15px",
            fontWeight: "bold",
          }}
        />
        <Typography
          variant="caption"
          sx={{
            fontSize:"15px",
            fontWeight: "150",
            fontFamily: "Poppins",
          }}
        >
          AIRA {new Date().getFullYear()}
        </Typography>
      </Stack>
    </Box>
  );
};

export default LoginFooter;
