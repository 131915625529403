import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mui/material";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import "react-phone-number-input/style.css";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import * as yup from "yup";
import CommonButton from "../../components/CustomButtons/CommonButton";
import StyledTextField from "../../components/StyledTextField/StyledTextField";
import { forgotpassword } from "../../network/networks";
import Strings from "../../values/strings";
import { Dots } from "react-activity";
import { useState } from "react";
import Colors from "../../values/colors";

const forgotPasswordSchema = yup
  .object()
  .shape({
    email: yup
      .string()
      .trim(Strings.no_space)
      .email(Strings.enter_email)
      .required(Strings.required_field),
  })
  .required();

const ForgotPassword = ({ handleClose, isDialog = false }) => {
  const [disable, setDisable] = useState(false);

  const {
    control: forgotPasswordControl,
    handleSubmit: forgotPasswordHandleSubmit,
    formState: { errors: forgotPasswordErrors },
  } = useForm({
    resolver: yupResolver(forgotPasswordSchema),
    defaultValues: {
      email: "",
    },
  });

  const onForgotPasswordSubmit = (formData) => {
    callForgotPwdApi(formData);
  };

  const callForgotPwdApi = (data) => {
    let forgotPasswordFormData = {
      email: data.email,
    };
    setDisable(true)
    axios.post(forgotpassword, forgotPasswordFormData, { skipAuthRefresh: true, })
      .then((response) => {
        setDisable(false)
        handleClose();
        Swal.fire({
          icon: "success",
          position: "center",
          title: "Success",
          text: Strings.password_reset_link_msg,
        })
      })
      .catch((err) => {
        setDisable(false)
        if (isDialog) handleClose();
        Swal.fire({
          icon: "error",
          position: "center",
          title: "Oop's",
          text: err.response.data[0].msg,
        });
      });
  };

  return (
    <form
      id="forgotPasswordForm"
      onSubmit={forgotPasswordHandleSubmit(onForgotPasswordSubmit)}
    >
      <Grid container rowSpacing={6} spacing={1}>
        <Grid item xs={12}>
          {Strings.please_enter_your_email_to_reset_your_password}
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={forgotPasswordControl}
            id="email"
            name="email"
            render={({ field }) => {
              return (
                <StyledTextField
                  {...field}
                  type={"email"}
                  className=""
                  placeholder={Strings.email}
                  fullWidth
                />
              );
            }}
          />
          <ErrorMessage
            errors={forgotPasswordErrors}
            name="email"
            render={({ message }) => (
              <p className="error-message" style={{ color: "red", fontSize: 12 }}>{message}</p>
            )}
          />
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={6} md={6} display={isDialog ? "" : "none"}>
          <CommonButton
            fullWidth
            onClick={() => { if (isDialog) handleClose() }}
          >
            {Strings.cancel}
          </CommonButton>
        </Grid>
        <Grid item xs={12} sm={6} md={6} ml={isDialog ? "" : "auto"}>
          <CommonButton disabled={disable} isCTA={true} fullWidth type="submit">
            {disable ? (
              <Dots color={Colors.white} />
            ) : (
              Strings.validate
            )}
          </CommonButton>
        </Grid>
      </Grid>
    </form>
  )
}

export default ForgotPassword;