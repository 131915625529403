import CloseIcon from "@mui/icons-material/Close";
import {
  Backdrop,
  Button,
  Card,
  CardContent,
  CircularProgress,
  DialogActions,
  DialogContent,
  FormHelperText,
  Grid,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead, TablePagination, TableRow, Typography
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import _ from "lodash";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import Images from "../../../assets";
import Search from "../../../components/Search/Search";
import ESTablePaginationAction from "../../../esCommonComponent/ESTablePaginationAction";
import { elisProviderDetails, elisProviders } from "../../../network/networks";
import Colors from "../../../values/colors";
import '../styles.css';
import ScreenLoader from "../../../components/Loader/LoaderScreen";
import Strings from "../../../values/strings";
const costCenterHeader = [
  { id: "LIFNR", label: "LIFNR" },
  { id: "NAME1", label: Strings.name },
  { id: "ORT01", label: "ORT01" },
  { id: "PSTLZ", label: "PSTLZ" },
  { id: "STCEG", label: "STCEG" },
  { id: "STCD1", label: "STCD1" },
  { id: "STCD2", label: "STCD2" },
];
const createAddressData = (
  NAME1,
  LIFNR,
  ORT01,
  PSTLZ,
  STCEG,
  STCD2,
  STCD1,
) => {
  return {
    NAME1,
    LIFNR,
    ORT01,
    PSTLZ,
    STCEG,
    STCD2,
    STCD1,
  };
};
const ProviderDialog = (props) => {
  const { onClose, open, onSelectItem, setShowDialogLoader, showDialogLoader, isWebEntry, pageContent } = props;
  console.log("showDialogLoader", showDialogLoader)
  const [isDataFetching, setIsDataFetching] = useState(false);
  const [costCenterData, setCostCenterData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [noResultFound, setNoResultFound] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [s_lifnr, setS_lifnr] = useState("")
  const [s_lifnr_debounce] = useDebounce(s_lifnr, 1500)
  const [s_name, setS_name] = useState("")
  const [s_name_debounce] = useDebounce(s_name, 1500)
  const [s_ort01, setS_ort01] = useState("")
  const [s_ort01_debounce] = useDebounce(s_ort01, 1500)
  const [s_pstlz, setS_pstlz] = useState("")
  const [s_pstlz_debounce] = useDebounce(s_pstlz, 1500)
  const [s_stceg, setS_stceg] = useState("")
  const [s_stceg_debounce] = useDebounce(s_stceg, 1500)
  const [s_stcd2, setS_stcd2] = useState("")
  const [s_stcd2_debounce] = useDebounce(s_stcd2, 1500)
  const [s_stcd1, setS_stcd1] = useState("")
  const [s_stcd1_debounce] = useDebounce(s_stcd1, 1500)
  const [selectedRow, setSelectedRow] = useState("");
  const formAxiosInstance = axios.create();
  formAxiosInstance.interceptors.request.use(function (config) {
    config.headers.Authorization = `Bearer ${isWebEntry ? pageContent.token.token : localStorage.getItem('token')}`;
    return config;
  });
  const fetchCostCenterList = useCallback(() => {
    setCostCenterData([])
    setIsFetchingData(true)
    let dataToSubmit = {
      limit: rowsPerPage,
      page: page + 1,
    }

    formAxiosInstance.get(elisProviders, { params: dataToSubmit }).then((res) => {
      let { data, meta } = res.data;
      let { total } = meta
      setTotalCount(total);
      let _data = data.map((item) => {
        let _item = { ...item };
        return createAddressData(
          _item.NAME1,
          _item.LIFNR,
          _item.ORT01,
          _item.PSTLZ,
          _item.STCEG,
          _item.STCD2,
          _item.STCD1,
        );
      });
      setNoResultFound(_.isEmpty(_data));
      setTimeout(() => {
        setCostCenterData(_data)
        setIsFetchingData(false);
      }, 1000);
    })
      .catch((err) => {
        setIsDataFetching(false);
      });
  }, [page, rowsPerPage]);

  const filterCostCenterList = useCallback(() => {
    setCostCenterData([])
    setIsFetchingData(true)
    let dataToSubmit = {
      limit: rowsPerPage,
      page: 1,
      s_lifnr: s_lifnr_debounce,
      s_name: s_name_debounce,
      s_ort01: s_ort01_debounce,
      s_pstlz: s_pstlz_debounce,
      s_stceg: s_stceg_debounce,
      s_stcd2: s_stcd2_debounce,
      s_stcd1: s_stcd1_debounce,
    }
    formAxiosInstance.get(elisProviders, { params: dataToSubmit }).then((res) => {
      let { data, meta } = res.data;
      let { total } = meta
      setTotalCount(total);
      let _data = data.map((item) => {
        let _item = { ...item };
        return createAddressData(
          _item.NAME1,
          _item.LIFNR,
          _item.ORT01,
          _item.PSTLZ,
          _item.STCEG,
          _item.STCD2,
          _item.STCD1,
        );
      });
      setNoResultFound(_.isEmpty(_data));
      setTimeout(() => {
        setCostCenterData(_data)
        setIsFetchingData(false);
      }, 1000);
    })
      .catch((err) => {
        setIsDataFetching(false);
      });
  }, [rowsPerPage, s_lifnr_debounce, s_name_debounce, s_ort01_debounce, s_pstlz_debounce, s_stcd1_debounce, s_stcd2_debounce, s_stceg_debounce]);
  useEffect(() => {
    fetchCostCenterList();
  }, [fetchCostCenterList]);
  const onRowSelect = (item) => {
    setShowDialogLoader(true)
    formAxiosInstance.post(elisProviderDetails, item).then((res) => {
      console.log("res", res.data)
      let data = {
        "idPaymentItem": res.data.LIFNR,
        "incotermsLabel": res.data.TEXT1,
        "nomDonnfourni": res.data.NAME1,
        "textVarText008": res.data.ZTERM,
        "incoterms2ndPartie": "",
        "textVarID007": "",
        "textVarID009": "",
        "inmac_store_name": res.data.NAME1,
        "inmac_add1": res.data.SMTP_ADDR,
        "inmac_add2": res.data.ORT01,
        "s_country": res.data.LAND1,
        "s_tel": res.data.TELF1,
        "s_fax": res.data.TELFX,

      }
      onSelectItem(data)
      onClose()
    })
      .catch((err) => {
        setIsDataFetching(false);
      });
  }
  const onClear = () => {
    setPage(0)
    setS_lifnr("")
    setS_name("")
    setS_ort01("")
    setS_pstlz("")
    setS_stceg("")
    setS_stcd2("")
    setS_stcd1("")
    fetchCostCenterList()
  }
  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth="lg"
      fullWidth
      style={
        {
          zIndex: "9999999",
        }
      }
      disableEscapeKeyDown
    >
      <DialogTitle sx={{
        bordercostCentertom: "1px solid #e4e0e0", padding: "8px 10px",
        borderBottom: "1px solid #c9c6c6",
        marginBottom: "3px",
      }}>
        {Strings.providers}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: "10px !important",
              top: "6px !important",
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent sx={{ paddingTop: "10px !important" }}>
        {
          showDialogLoader &&
          <Backdrop
            sx={{ color: Colors.white, zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={showDialogLoader}
            onClick={() => setShowDialogLoader(false)}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        }
        <Grid container spacing={2}>
          <Grid item xs={3} md={3} sm={3} sx={{ paddingLeft: "5px !important" }}>
            <div style={{
              // height: "100%"
            }}>
              <div>
                <FormHelperText>LIFNR</FormHelperText>
                <Search
                  handleSearchEvent={(e) => {
                    // setPage(0);
                    if (_.isEmpty(e.target.value)) {
                      setS_lifnr(e.target.value);
                    }
                    setS_lifnr(e.target.value);
                  }}
                  value={s_lifnr}
                  placeholder={`${Strings.search} LIFNR`}
                  sxStyleProps={{
                    padding: "0px 0px",
                    ".MuiOutlinedInput-input": {
                      fontSize: "12px"
                    },
                    ".MuiSvgIcon-root": {
                      width: "24px",
                      height: "18px"
                    },
                    ".MuiInputBase-formControl": {
                      padding: "0px 0px",
                      borderRadius: "10px",
                      height: "30px !important",
                      marginBottom: "5px"
                    }
                  }}
                />
              </div>
              <div>
                <FormHelperText>{Strings.name}</FormHelperText>
                <Search
                  handleSearchEvent={(e) => {
                    // setPage(0);
                    if (_.isEmpty(e.target.value)) {
                      setS_name(e.target.value);
                    }
                    setS_name(e.target.value);
                  }}
                  value={s_name}
                  placeholder={`${Strings.search} ${Strings.name}`}
                  sxStyleProps={{
                    padding: "0px 0px",
                    ".MuiOutlinedInput-input": {
                      fontSize: "12px"
                    },
                    ".MuiSvgIcon-root": {
                      width: "24px",
                      height: "18px"
                    },
                    ".MuiInputBase-formControl": {
                      padding: "0px 0px",
                      borderRadius: "10px",
                      height: "30px !important",
                      marginBottom: "5px"
                    }
                  }}
                />
              </div>
              <div>
                <FormHelperText>ORT01</FormHelperText>
                <Search
                  handleSearchEvent={(e) => {
                    // setPage(0);
                    if (_.isEmpty(e.target.value)) {
                      setS_ort01(e.target.value);
                    }
                    setS_ort01(e.target.value);
                  }}
                  value={s_ort01}
                  placeholder={`${Strings.search} ORT01`}
                  sxStyleProps={{
                    padding: "0px 0px",
                    ".MuiOutlinedInput-input": {
                      fontSize: "12px"
                    },
                    ".MuiSvgIcon-root": {
                      width: "24px",
                      height: "18px"
                    },
                    ".MuiInputBase-formControl": {
                      padding: "0px 0px",
                      borderRadius: "10px",
                      height: "30px !important",
                      marginBottom: "5px"
                    }
                  }}
                />
              </div>
              <div>
                <FormHelperText>PSTLZ</FormHelperText>
                <Search
                  handleSearchEvent={(e) => {
                    // setPage(0);
                    if (_.isEmpty(e.target.value)) {
                      setS_pstlz(e.target.value);
                    }
                    setS_pstlz(e.target.value);
                  }}
                  value={s_pstlz}
                  placeholder={`${Strings.search} PSTLZ`}
                  sxStyleProps={{
                    padding: "0px 0px",
                    ".MuiOutlinedInput-input": {
                      fontSize: "12px"
                    },
                    ".MuiSvgIcon-root": {
                      width: "24px",
                      height: "18px"
                    },
                    ".MuiInputBase-formControl": {
                      padding: "0px 0px",
                      borderRadius: "10px",
                      height: "30px !important",
                      marginBottom: "5px"
                    }
                  }}
                />
              </div>
              <div>
                <FormHelperText>STCEG</FormHelperText>
                <Search
                  handleSearchEvent={(e) => {
                    // setPage(0);
                    if (_.isEmpty(e.target.value)) {
                      setS_stceg(e.target.value);
                    }
                    setS_stceg(e.target.value);
                  }}
                  value={s_stceg}
                  placeholder={`${Strings.search} STCEG`}
                  sxStyleProps={{
                    padding: "0px 0px",
                    ".MuiOutlinedInput-input": {
                      fontSize: "12px"
                    },
                    ".MuiSvgIcon-root": {
                      width: "24px",
                      height: "18px"
                    },
                    ".MuiInputBase-formControl": {
                      padding: "0px 0px",
                      borderRadius: "10px",
                      height: "30px !important",
                      marginBottom: "5px"
                    }
                  }}
                />
              </div>
              <div>
                <FormHelperText>STCD2</FormHelperText>
                <Search
                  handleSearchEvent={(e) => {
                    // setPage(0);
                    if (_.isEmpty(e.target.value)) {
                      setS_stcd2(e.target.value);
                    }
                    setS_stcd2(e.target.value);
                  }}
                  value={s_stcd2}
                  placeholder={`${Strings.search} STCD2`}
                  sxStyleProps={{
                    padding: "0px 0px",
                    ".MuiOutlinedInput-input": {
                      fontSize: "12px"
                    },
                    ".MuiSvgIcon-root": {
                      width: "24px",
                      height: "18px"
                    },
                    ".MuiInputBase-formControl": {
                      padding: "0px 0px",
                      borderRadius: "10px",
                      height: "30px !important",
                      marginBottom: "5px"
                    }
                  }}
                />
              </div>
              <div>
                <FormHelperText>STCD1</FormHelperText>
                <Search
                  handleSearchEvent={(e) => {
                    // setPage(0);
                    if (_.isEmpty(e.target.value)) {
                      setS_stcd1(e.target.value);
                    }
                    setS_stcd1(e.target.value);
                  }}
                  value={s_stcd1}
                  placeholder={`${Strings.search} STCD1`}
                  sxStyleProps={{
                    padding: "0px 0px",
                    ".MuiOutlinedInput-input": {
                      fontSize: "12px"
                    },
                    ".MuiSvgIcon-root": {
                      width: "24px",
                      height: "18px"
                    },
                    ".MuiInputBase-formControl": {
                      padding: "0px 0px",
                      borderRadius: "10px",
                      height: "30px !important",
                      marginBottom: "5px"
                    }
                  }}
                />
              </div>
            </div>
            <div style={{ textAlign: "end", marginTop: "10px" }}>
              <Button size="small" variant="outlined" autoFocus
                sx={{ mr: 1 }}
                onClick={() => {
                  setPage(0)
                  filterCostCenterList()
                }}>
                {Strings.apply}
              </Button>
              <Button size="small" variant="outlined" color="error" onClick={() => onClear()}>
                {Strings.clear}
              </Button>
            </div>
          </Grid>
          <Grid item xs={9} md={9} sm={9}>
            <div>
              <Card style={{ boxShadow: "0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 1px 0px 1px rgb(0 0 0 / 14%), 0px -1px 0px 0px rgb(0 0 0 / 12%)" }}>
                <CardContent sx={{ padding: "0px !important" }}>
                  <TableContainer style={{
                    minHeight: "400px",
                    maxHeight: "400px"
                  }}>
                    <Table stickyHeader>
                      <TableHead style={{ textTransform: "capitalize" }} >
                        <TableRow>
                          {costCenterHeader.map((headerItem, headerItemIndex) => {
                            return (
                              <TableCell
                                key={headerItemIndex}
                                style={{
                                  fontSize: "12px",
                                  textAlign: "left",
                                  fontFamily: "Inter",
                                  fontWeight: 600,
                                  whiteSpace: "nowrap",
                                  color: "white",
                                  backgroundColor: Colors.airaPrimary,
                                  padding: "10px"
                                }}
                              >
                                {headerItem.label}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody >
                        {isFetchingData &&
                          _.range(rowsPerPage).map((rowNumber) => (
                            <TableRow key={rowNumber}>
                              {_.range(costCenterHeader.length).map((keyNumber) => (
                                <TableCell
                                  style={{ minWidth: "100px" }}
                                  className="p-1"
                                  key={`${rowNumber}.${keyNumber}`}>
                                  <Skeleton
                                    variant="rectangular"
                                    height={24}
                                    sx={{ borderRadius: "12px" }}
                                  />
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}

                        {!isFetchingData && noResultFound && (
                          <TableRow style={{ padding: '0px !important' }}>
                            <TableCell colSpan={8} className="p-1">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignContent: "center",
                                  textAlign: "center",
                                }}
                              >
                                <img
                                  src={Images.emptyState}
                                  style={{
                                    alignSelf: "center",
                                    //width: "100%",
                                    width: "300px",
                                    height: "300px",
                                  }}
                                  alt=""
                                />
                                <Typography
                                  component="span"
                                  className="styled-text Bonjour-Julien"
                                >
                                  Nothing here
                                </Typography>
                              </div>
                            </TableCell>
                          </TableRow>
                        )}


                        {costCenterData.map((rowItem, rowIdx) => {
                          return (
                            <TableRow
                              onClick={(e) => {
                                setSelectedRow(rowItem)
                                if (e.detail == 2) {
                                  onRowSelect(rowItem)
                                }
                              }}
                              hover
                              style={{
                                cursor: "pointer",
                                background: selectedRow.LIFNR == rowItem.LIFNR ? "#dad9d9" : "none"
                              }}
                              key={rowItem.costCenter_id}>
                              {costCenterHeader.map((costCenterHeaderItem, costCenterHeaderIdx) => {
                                let value = rowItem[costCenterHeaderItem.id];
                                return (
                                  <TableCell
                                    key={costCenterHeaderItem.id}
                                    className="p-2"
                                    style={{
                                      fontSize: "13px",
                                      textAlign: "left",
                                      fontFamily: "Inter",
                                      fontWeight: 500,
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <div style={{ float: "right", fontFamily: "Inter" }}>
                    <TablePagination
                      labelRowsPerPage={Strings.rows_per_page}
                      component="div"
                      rowsPerPageOptions={[5, 10, 20]}
                      count={totalCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                        native: true,
                      }}
                      onPageChange={(event, newPage) => {
                        setPage(newPage);
                      }}
                      onRowsPerPageChange={(event) => {
                        setRowsPerPage(parseInt(event.target.value, 10));
                        setPage(0);
                      }}
                      ActionsComponent={ESTablePaginationAction}
                    />
                  </div>
                </CardContent>
              </Card>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" autoFocus onClick={() => {
          if (!_.isEmpty(selectedRow))
            onRowSelect(selectedRow)
        }
        }>
          {Strings.validate}
        </Button>
        <Button variant="outlined" color="error" onClick={onClose}>
          {Strings.cancel}
        </Button>
      </DialogActions>
    </Dialog >
  );
};

ProviderDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default ProviderDialog;
