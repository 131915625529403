import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { appChangeTheme } from "../../network/networks";
const initialState = {
  isDarkMode: localStorage.getItem("isDarkMode"),
};
const themeSlice = createSlice({
  name: "theme",
  initialState: {
    isDarkMode: false,
  },
  reducers: {
    setInitialMode: (state, action) => {
      //console.log("initial", action.payload.isDarkMode);
      return { isDarkMode: action.payload.isDarkMode };
    },
    toggleMode: (state, action) => {
      localStorage.setItem("isDarkMode", !state.isDarkMode);
      if (action.payload !== "login") {
        axios
          .put(appChangeTheme, { theme: !state.isDarkMode ? "dark" : "light" })
          .then((response) => {})
          .catch((err) => {
            console.log("err ", err);
            console.log("err ", err.response);
          });
      }
      return { isDarkMode: !state.isDarkMode };
    },
    updateTheme: (state, action) => {
      localStorage.setItem("isDarkMode", state.isDarkMode);
      // window.location.reload();
    },
  },
});

const { reducer, actions } = themeSlice;
export const { toggleMode, setInitialMode, updateTheme } = actions;
export default reducer;
