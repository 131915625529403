import { Controller, useFormContext } from "react-hook-form";
import StyledTextField from "../../../../../../../components/StyledTextField/StyledTextField";
import { ErrorMessage } from "@hookform/error-message";
import { FormHelperText, Grid, Typography } from "@mui/material";
import CustomRSelect from "../../../../../../../components/CustomRSelect/CustomRSelect";
import axios from "axios";
import { appCountries } from "../../../../../../../network/networks";
import { useCallback, useEffect, useState } from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import styled from "styled-components";
import { countriesData } from "../../../../../../profile/countries";

const continent = [
  { label: "Africa", value: "Africa" },
  { label: "South America", value: "South America" },
  { label: "North America", value: "North America" },
  { label: "Europe", value: "Europe" },
  { label: "Asia", value: "Asia" },
  { label: "Australia/Oceania", value: "Australia/Oceania" },
  { label: "Antartica", value: "Antartica" },
];
const DatePickerUI = styled.ul`
  .MuiTextField-root {
    width: 100%;
  }
  .MuiOutlinedInput-input {
    padding: 8px 10px;
  }
`;
const EVisaApplicant = () => {
  const {
    control,
    formState: { errors },
    clearErrors,
    getValues,
  } = useFormContext();
  const [countries, setCountries] = useState(countriesData);

  return (
    <div>
      <Typography variant="h6">E-Visa Applicant</Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div className="form-group">
            <FormHelperText color="muted">{"E-Visa Applicants"}</FormHelperText>
            <Controller
              rules={{ required: "This field is required." }}
              name={"evisaAaplicant"}
              id={"evisaAaplicant"}
              control={control}
              render={({ field }) => {
                return (
                  <CustomRSelect
                    {...field}
                    fullWidth
                    type="select"
                    id="evisaAaplicant"
                    options={[
                      { label: "Adult", value: "adult" },
                      { label: "Minor", value: "minor" },
                    ]}
                  />
                );
              }}
            />
            <ErrorMessage
              errors={errors}
              name={"evisaAaplicant"}
              render={({ message }) => (
                <FormHelperText
                  style={{
                    color: "#bf1650",
                  }}
                >
                  {message}
                </FormHelperText>
              )}
            />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="form-group">
            <FormHelperText color="muted">{"Visa Entry Type"}</FormHelperText>
            <Controller
              rules={{ required: "This field is required." }}
              name={"visaEntries"}
              id={"visaEntries"}
              control={control}
              render={({ field }) => {
                return (
                  <CustomRSelect
                    {...field}
                    fullWidth
                    type="select"
                    id="visaEntries"
                    options={[
                      { label: "Single Entry Visa", value: "single" },
                      { label: "Transit Entry Visa", value: "transit" },
                      { label: "Courtesy Visa", value: "courtesy" },
                      {
                        label: "East Africa Tourist Visa",
                        value: "eastAfricaTourist",
                      },
                      { label: "Multiple Entry Visa", value: "multiple" },
                    ]}
                  />
                );
              }}
            />
            <ErrorMessage
              errors={errors}
              name={"visaEntries"}
              render={({ message }) => (
                <FormHelperText
                  style={{
                    color: "#bf1650",
                  }}
                >
                  {message}
                </FormHelperText>
              )}
            />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="form-group">
            <FormHelperText color="muted">{"Type Of Visa"}</FormHelperText>
            <Controller
              rules={{ required: "This field is required." }}
              name={"typeOfVisa"}
              id={"typeOfVisa"}
              control={control}
              render={({ field }) => {
                return (
                  <CustomRSelect
                    {...field}
                    fullWidth
                    type="select"
                    id="typeOfVisa"
                    options={[
                      { label: "Tourism", value: "Tourism" },
                      { label: "Visit", value: "Visit" },
                      { label: "Medical", value: "Medical" },
                      { label: "Business", value: "Business" },
                      { label: "Work", value: "Work" },
                    ]}
                  />
                );
              }}
            />
            <ErrorMessage
              errors={errors}
              name={"typeOfVisa"}
              render={({ message }) => (
                <FormHelperText
                  style={{
                    color: "#bf1650",
                  }}
                >
                  {message}
                </FormHelperText>
              )}
            />
          </div>
        </Grid>
      </Grid>
      <br />
      <div>
        <Typography variant="h6">Nationality And Residence</Typography>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div className="form-group">
              <FormHelperText color="muted">
                {"Nationality At Birth"}
              </FormHelperText>
              <Controller
                rules={{ required: "This field is required." }}
                name={"nationalityatbirth"}
                id={"nationalityatbirth"}
                control={control}
                render={({ field }) => {
                  return (
                    <CustomRSelect
                      {...field}
                      fullWidth
                      type="select"
                      id="nationalityatbirth"
                      options={countries}
                    />
                  );
                }}
              />
              <ErrorMessage
                errors={errors}
                name={"nationalityatbirth"}
                render={({ message }) => (
                  <FormHelperText
                    style={{
                      color: "#bf1650",
                    }}
                  >
                    {message}
                  </FormHelperText>
                )}
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="form-group">
              <FormHelperText color="muted">
                {"Present Nationality, if Different"}
              </FormHelperText>
              <Controller
                rules={{ required: "This field is required." }}
                name={"presentNationality"}
                id={"presentNationality"}
                control={control}
                render={({ field }) => {
                  return (
                    <CustomRSelect
                      {...field}
                      fullWidth
                      type="select"
                      id="presentNationality"
                      options={countries}
                    />
                  );
                }}
              />
              <ErrorMessage
                errors={errors}
                name={"presentNationality"}
                render={({ message }) => (
                  <FormHelperText
                    style={{
                      color: "#bf1650",
                    }}
                  >
                    {message}
                  </FormHelperText>
                )}
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="form-group">
              <FormHelperText color="muted">
                {"Applicants Continent of Residence"}
              </FormHelperText>
              <Controller
                rules={{ required: "This field is required." }}
                name={"continentOfResidence"}
                id={"continentOfResidence"}
                control={control}
                render={({ field }) => {
                  return (
                    <CustomRSelect
                      {...field}
                      fullWidth
                      type="select"
                      id="continentOfResidence"
                      options={continent}
                    />
                  );
                }}
              />
              <ErrorMessage
                errors={errors}
                name={"continentOfResidence"}
                render={({ message }) => (
                  <FormHelperText
                    style={{
                      color: "#bf1650",
                    }}
                  >
                    {message}
                  </FormHelperText>
                )}
              />
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div className="form-group">
              <FormHelperText color="muted">
                {" Applicants Country of Residence"}
              </FormHelperText>
              <Controller
                rules={{ required: "This field is required." }}
                name={"countryOfResidence"}
                id={"countryOfResidence"}
                control={control}
                render={({ field }) => {
                  return (
                    <CustomRSelect
                      {...field}
                      fullWidth
                      type="select"
                      id="countryOfResidence"
                      options={countries}
                    />
                  );
                }}
              />
              <ErrorMessage
                errors={errors}
                name={"countryOfResidence"}
                render={({ message }) => (
                  <FormHelperText
                    style={{
                      color: "#bf1650",
                    }}
                  >
                    {message}
                  </FormHelperText>
                )}
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="form-group">
              <FormHelperText color="muted">
                {"Applicants Permanent Address"}
              </FormHelperText>
              <Controller
                rules={{ required: "This field is required." }}
                name={"physicalAddress"}
                id={"physicalAddress"}
                control={control}
                render={({ field }) => {
                  return (
                    <StyledTextField
                      {...field}
                      fullWidth
                      type="select"
                      id="physicalAddress"
                      row={4}
                    />
                  );
                }}
              />
              <ErrorMessage
                errors={errors}
                name={"physicalAddress"}
                render={({ message }) => (
                  <FormHelperText
                    style={{
                      color: "#bf1650",
                    }}
                  >
                    {message}
                  </FormHelperText>
                )}
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="form-group">
              <FormHelperText color="muted">
                {"Applicants Email"}
              </FormHelperText>
              <Controller
                rules={{ required: "This field is required." }}
                name={"email"}
                id={"email"}
                control={control}
                render={({ field }) => {
                  return (
                    <StyledTextField
                      {...field}
                      fullWidth
                      type="text"
                      id="email"
                    />
                  );
                }}
              />
              <ErrorMessage
                errors={errors}
                name={"email"}
                render={({ message }) => (
                  <FormHelperText
                    style={{
                      color: "#bf1650",
                    }}
                  >
                    {message}
                  </FormHelperText>
                )}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <br />
      <div>
        <Typography variant="h6">Passport Information</Typography>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div className="form-group">
              <FormHelperText color="muted">{"Passport Number"}</FormHelperText>
              <Controller
                rules={{
                  required: "This field is required.",
                  minLength: {
                    value: 12,
                    message: "Must be twelve characters",
                  },
                  manLength: {
                    value: 12,
                    message: "Only Twelve Characters Allowed",
                  },
                }}
                name={"passportNumber"}
                id={"passportNumber"}
                control={control}
                render={({ field }) => {
                  return (
                    <StyledTextField
                      type="number"
                      {...field}
                      fullWidth
                      id="passportNumber"
                    />
                  );
                }}
              />
              <ErrorMessage
                errors={errors}
                name={"passportNumber"}
                render={({ message }) => (
                  <FormHelperText
                    style={{
                      color: "#bf1650",
                    }}
                  >
                    {message}
                  </FormHelperText>
                )}
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="form-group">
              <FormHelperText color="muted">
                {"Passport Place of issue"}
              </FormHelperText>
              <Controller
                rules={{ required: "This field is required." }}
                name={"passportPlaceOfIssue"}
                id={"passportPlaceOfIssue"}
                control={control}
                render={({ field }) => {
                  return (
                    <StyledTextField
                      type="type"
                      {...field}
                      fullWidth
                      id="passportPlaceOfIssue"
                    />
                  );
                }}
              />
              <ErrorMessage
                errors={errors}
                name={"passportPlaceOfIssue"}
                render={({ message }) => (
                  <FormHelperText
                    style={{
                      color: "#bf1650",
                    }}
                  >
                    {message}
                  </FormHelperText>
                )}
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="form-group">
              <FormHelperText color="muted">{"Travel Document"}</FormHelperText>
              <Controller
                rules={{ required: "This field is required." }}
                name={"travelDocument"}
                id={"travelDocument"}
                control={control}
                render={({ field }) => {
                  return (
                    <CustomRSelect
                      {...field}
                      fullWidth
                      type="select"
                      id="travelDocument"
                      options={[
                        {
                          label: "National Passport",
                          value: "National Passport",
                        },
                        {
                          label: "Stateless Passport",
                          value: "Stateless Passport",
                        },
                        { label: "Laissez Passer", value: "Laissez Passer" },
                      ]}
                    />
                  );
                }}
              />
              <ErrorMessage
                errors={errors}
                name={"travelDocument"}
                render={({ message }) => (
                  <FormHelperText
                    style={{
                      color: "#bf1650",
                    }}
                  >
                    {message}
                  </FormHelperText>
                )}
              />
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div className="form-group">
              <FormHelperText color="muted">
                {"Passport Date of issue"}
              </FormHelperText>
              <Controller
                rules={{ required: "This field is required." }}
                name={"passportDateOfIssue"}
                id={"passportDateOfIssue"}
                control={control}
                render={({ field }) => {
                  return (
                    <DatePickerUI>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DemoContainer components={["DatePicker"]}>
                          <DatePicker
                            label="Passport Date of issue"
                            fullWidth
                            {...field}
                            sx={{
                              "MuiTextField-root": {
                                width: "100%",
                              },
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </DatePickerUI>
                  );
                }}
              />
              <ErrorMessage
                errors={errors}
                name={"passportDateOfIssue"}
                render={({ message }) => (
                  <FormHelperText
                    style={{
                      color: "#bf1650",
                    }}
                  >
                    {message}
                  </FormHelperText>
                )}
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="form-group">
              <FormHelperText color="muted">
                {"Passport Expiry Date"}
              </FormHelperText>
              <Controller
                rules={{ required: "This field is required." }}
                name={"passportExpiryDate"}
                id={"passportExpiryDate"}
                control={control}
                render={({ field }) => {
                  return (
                    <DatePickerUI>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DemoContainer components={["DatePicker"]}>
                          <DatePicker
                            label="Passport Expiry Date"
                            fullWidth
                            {...field}
                            sx={{
                              "MuiTextField-root": {
                                width: "100%",
                              },
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </DatePickerUI>
                  );
                }}
              />
              <ErrorMessage
                errors={errors}
                name={"passportExpiryDate"}
                render={({ message }) => (
                  <FormHelperText
                    style={{
                      color: "#bf1650",
                    }}
                  >
                    {message}
                  </FormHelperText>
                )}
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="form-group">
              <FormHelperText color="muted">
                {"Passport Issued By"}
              </FormHelperText>
              <Controller
                rules={{ required: "This field is required." }}
                name={"passportIssuedBy"}
                id={"passportIssuedBy"}
                control={control}
                render={({ field }) => {
                  return (
                    <StyledTextField
                      {...field}
                      fullWidth
                      type="text"
                      id="passportIssuedBy"
                    />
                  );
                }}
              />
              <ErrorMessage
                errors={errors}
                name={"passportIssuedBy"}
                render={({ message }) => (
                  <FormHelperText
                    style={{
                      color: "#bf1650",
                    }}
                  >
                    {message}
                  </FormHelperText>
                )}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default EVisaApplicant;
