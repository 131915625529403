import { Controller, useFormContext } from "react-hook-form";
import StyledTextField from "../../../../../../../components/StyledTextField/StyledTextField";
import { ErrorMessage } from "@hookform/error-message";
import { FormHelperText, Grid, Typography } from "@mui/material";
import CustomRSelect from "../../../../../../../components/CustomRSelect/CustomRSelect";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import styled from "styled-components";

const DatePickerUI = styled.ul`
  .MuiTextField-root {
    width: 100%;
  }
  .MuiOutlinedInput-input {
    padding: 8px 10px;
  }
`;
const Travel = () => {
  const {
    control,
    formState: { errors },
    clearErrors,
    getValues,
  } = useFormContext();

  return (
    <div>
      <Typography variant="h6">Travelling Information</Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div className="form-group">
            <FormHelperText color="muted">
              {"Previous visits to El Salvador"}
            </FormHelperText>
            <Controller
              rules={{ required: "This field is required." }}
              name={"previosVisits"}
              id={"previosVisits"}
              control={control}
              render={({ field }) => {
                return (
                  <CustomRSelect
                    {...field}
                    fullWidth
                    type="select"
                    id="previosVisits"
                    options={[
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ]}
                  />
                );
              }}
            />
            <ErrorMessage
              errors={errors}
              name={"previosVisits"}
              render={({ message }) => (
                <FormHelperText
                  style={{
                    color: "#bf1650",
                  }}
                >
                  {message}
                </FormHelperText>
              )}
            />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="form-group">
            <FormHelperText color="muted">
              {"Are you travelling with minor"}
            </FormHelperText>
            <Controller
              rules={{ required: "This field is required." }}
              name={"travellingWithMinor"}
              id={"travellingWithMinor"}
              control={control}
              render={({ field }) => {
                return (
                  <CustomRSelect
                    {...field}
                    fullWidth
                    type="select"
                    id="travellingWithMinor"
                    options={[
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ]}
                  />
                );
              }}
            />
            <ErrorMessage
              errors={errors}
              name={"travellingWithMinor"}
              render={({ message }) => (
                <FormHelperText
                  style={{
                    color: "#bf1650",
                  }}
                >
                  {message}
                </FormHelperText>
              )}
            />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="form-group">
            <FormHelperText color="muted">
              {"Applicants Reason For Travel"}
            </FormHelperText>
            <Controller
              rules={{ required: "This field is required." }}
              name={"reasonForTravel"}
              id={"reasonForTravel"}
              control={control}
              render={({ field }) => {
                return (
                  <StyledTextField
                    {...field}
                    fullWidth
                    type="select"
                    id="reasonForTravel"
                    row={4}
                  />
                );
              }}
            />
            <ErrorMessage
              errors={errors}
              name={"reasonForTravel"}
              render={({ message }) => (
                <FormHelperText
                  style={{
                    color: "#bf1650",
                  }}
                >
                  {message}
                </FormHelperText>
              )}
            />
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div className="form-group">
            <FormHelperText color="muted">{"Point Of Entry"}</FormHelperText>
            <Controller
              rules={{ required: "This field is required." }}
              name={"pointOfEntry"}
              id={"pointOfEntry"}
              control={control}
              render={({ field }) => {
                return (
                  <StyledTextField
                    {...field}
                    fullWidth
                    type="text"
                    id="pointOfEntry"
                  />
                );
              }}
            />
            <ErrorMessage
              errors={errors}
              name={"pointOfEntry"}
              render={({ message }) => (
                <FormHelperText
                  style={{
                    color: "#bf1650",
                  }}
                >
                  {message}
                </FormHelperText>
              )}
            />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="form-group">
            <FormHelperText color="muted">{"Host Name"}</FormHelperText>
            <Controller
              rules={{ required: "This field is required." }}
              name={"hostName"}
              id={"hostName"}
              control={control}
              render={({ field }) => {
                return (
                  <StyledTextField
                    {...field}
                    fullWidth
                    type="text"
                    id="hostName"
                  />
                );
              }}
            />
            <ErrorMessage
              errors={errors}
              name={"hostName"}
              render={({ message }) => (
                <FormHelperText
                  style={{
                    color: "#bf1650",
                  }}
                >
                  {message}
                </FormHelperText>
              )}
            />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="form-group">
            <FormHelperText color="muted">
              {"Host Telephone Number"}
            </FormHelperText>
            <Controller
              rules={{ required: "This field is required." }}
              name={"hostNumber"}
              id={"hostNumber"}
              control={control}
              render={({ field }) => {
                return (
                  <StyledTextField
                    {...field}
                    fullWidth
                    type="text"
                    id="hostNumber"
                  />
                );
              }}
            />
            <ErrorMessage
              errors={errors}
              name={"hostNumber"}
              render={({ message }) => (
                <FormHelperText
                  style={{
                    color: "#bf1650",
                  }}
                >
                  {message}
                </FormHelperText>
              )}
            />
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div className="form-group">
            <FormHelperText color="muted">{"Arrival Date"}</FormHelperText>
            <Controller
              rules={{ required: "This field is required." }}
              name={"dateOfArrival"}
              id={"dateOfArrival"}
              control={control}
              render={({ field }) => {
                return (
                  <DatePickerUI>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          label="Arrival Date"
                          fullWidth
                          {...field}
                          sx={{
                            "MuiTextField-root": {
                              width: "100%",
                            },
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </DatePickerUI>
                );
              }}
            />
            <ErrorMessage
              errors={errors}
              name={"dateOfArrival"}
              render={({ message }) => (
                <FormHelperText
                  style={{
                    color: "#bf1650",
                  }}
                >
                  {message}
                </FormHelperText>
              )}
            />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="form-group">
            <FormHelperText color="muted">{"Departure Date"}</FormHelperText>
            <Controller
              rules={{ required: "This field is required." }}
              name={"departureDate"}
              id={"departureDate"}
              control={control}
              render={({ field }) => {
                return (
                  <DatePickerUI>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          label="Departure Date"
                          fullWidth
                          {...field}
                          sx={{
                            "MuiTextField-root": {
                              width: "100%",
                            },
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </DatePickerUI>
                );
              }}
            />
            <ErrorMessage
              errors={errors}
              name={"departureDate"}
              render={({ message }) => (
                <FormHelperText
                  style={{
                    color: "#bf1650",
                  }}
                >
                  {message}
                </FormHelperText>
              )}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Travel;
