import { Typography } from "@mui/material";
import LoginHeader from "../../components/LoginHeader/LoginHeader";
import SigningPage from "./SigningPage";

const SignOnLoaging = () => {


  return (
    <div>
      <LoginHeader />
      <SigningPage />
      <div style={{
        margin: "0 40px",
        padding: "15px 0",
        textAlign: "right"
      }}>
        <Typography style={{ fontSize: "14px" }}>&copy; AIRA {new Date().getFullYear()}</Typography>
      </div>
    </div>
  );
};

export default SignOnLoaging;
