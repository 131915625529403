import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { useSelector } from "react-redux";
import _ from "lodash";
import { OutlinedInput } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useState } from "react";
const theme = createTheme({
  components: {
    MuiTextField: {
      defaultProps: {
        variant: "standard",
        fullWidth: true,
        InputProps: {
          //disableunderline: true,
        },
      },
      styleOverrides: {
        root: {
          "& .MuiInput-root": {
            paddingLeft: "10px",
            paddingTop: "8px",
            paddingBottom: "5px",
            color: "black",
            fontSize: "14px",
            borderRadius: "5px",

            // background: "#f6f6f6",
            // border: "2px solid transparent",
            ":focus-within": {
              // background: "#fff",
              // border: "2px solid #6755df",
              borderRadius: "9px",
            },
          },
        },
      },
    },
  },
});

const StyledTextField = React.forwardRef((props, ref) => {
  const isDarkMode = useSelector((state) => state.theme.isDarkMode, _.isEqual);
  let isPassword = props.isPassword
  const [showPassword, setShowPassword] = useState(false);
  
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  return (
    <>
    {isPassword ? <OutlinedInput
    {...props}
    type={showPassword ? "text" : "password"}
    endAdornment={
      <InputAdornment position="end">
        <IconButton
        onClick={handleClickShowPassword}
          aria-label="toggle password visibility"
          edge="end"
        >
        {showPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </InputAdornment>
    } 
     {...props}
      variant="outlined"
      size="small"
      autoComplete="new-password"
      ref={ref}
      sx={{
        width: "100%",
        ".MuiFormHelperText-root": {
          marginRight: 0,
          marginLeft: 0,
          fontSize: "9px",
        },
        ".MuiOutlinedInput-root.Mui-disabled": {
          background: isDarkMode ? "#1c1b1b" : "#f1f1f1",
        },
        ".MuiInputBase-formControl": {},
      }}
    /> : <TextField
    {...props}
    variant="outlined"
    size="small"
    ref={ref}
    sx={{
      width: "100%",
      ".MuiFormHelperText-root": {
        marginRight: 0,
        marginLeft: 0,
        fontSize: "9px",
      },
      ".MuiOutlinedInput-root.Mui-disabled": {
        background: isDarkMode ? "#1c1b1b" : "#f1f1f1",
      },
      ".MuiInputBase-formControl": {},
    }}
    onChange={(e) => { // trimstart added for preventing blank value being submitted.
      props.onChange(e.target.value.trimStart())
    }} 
  /> }
    
    </>
  );
});

export default StyledTextField;
