import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const LIItems = [
  "1. Passport must be valid at time of entry.",
  "2. At least one blank page.",
  "3. You need a U.S. passport and either a Salvadoran visa or a one-entry tourist card to enter El Salvador.",
  "4. You may obtain a tourist card when you arrive at the airport or seaport from immigration officials for a $12 fee. The card is valid for 90 days.",
  "5. Providing false information will lead to automatic rejection of visa.",
  "6. Inappropriate document uploads will lead to automatic rejection of the visa.",
  "7. Visa processing fee is non-refundable.",
  "8. If you plan to remain in El Salvador for more than 90 days, you must apply in advance for a   multiple-entry visa, issued free of charge, from the Embassy of El Salvador.",
  "9. Currency Restrictions for entry : Currency in excess of 10,000 USD must be declared.",
  "10. Visa is required prior to entry into El Salvador.",
  "11. The possession of a visa is not the final authority to enter the Republic of El salvador.",
  "12. Engaging in any form of business or employment without a requisite permit or pass is an offense.",
  "13. The validity of a multiple entry visas (MEV) will range from six to twelve (6-12) months.",
  "14. Possession of a multiple entry visa (MEV) does not confer residency.",
  "15. In the event of lapse during payment please do not re-apply, contact support@pesaflow.com.",
  "16. Upload recent color passport size photo with a clear background.",
  "17. Holders of Diplomatic/Official/Service Passports coming on official duty qualify for Courtesy Visa.",
];

const tableItems = [
  {
    type: "SINGLE ENTRY VISA + Service Charge",
    amount: "US $51.00",
  },
  {
    type: "TRANSIT ENTRY VISA + Service Charge",
    amount: "US $21.00",
  },
  {
    type: "COURTESY ENTRY VISA + Service Charge",
    amount: "US $0.00",
  },
  {
    type: "EAST AFRICAN TOURIST VISA + Service Charge",
    amount: "US $101.00",
  },
  {
    type: "MULTIPLE ENTRY VISA PROCESSING FEE + Service Charge",
    amount: "US $11.00",
  },
  {
    type: "MULTIPLE ENTRY VISA + Service Charge",
    amount: "US $101.00",
  },
  {
    type: "Syr MULTIPLE ENTRY VISA + Service Charge",
    amount: "US $161.00",
  },
];
const AppInfo = () => {
  return (
    <>
      <h4>1. Important Information</h4>
      <Typography variant="subtitle1" style={{ marginTop: "10px" }}>
        EL SALVADOR EVISA
      </Typography>
      <Typography
        variant="body2"
        style={{
          marginTop: "10px",
          color: "gray",
          fontSize: "12px",
        }}
      >
        Issued to persons whose nationalities require visa to enter El Salvador
        either for Tourism, Visit, Medical, Business or Work.
      </Typography>
      <div
        style={{
          background: "#f9d5dc",
          padding: "10px",
          borderRadius: 5,
          marginTop: "10px",
        }}
      >
        <Typography style={{ fontWeight: "500" }}>
          REQUIREMENTS FOR EL SALVADOR EVISA
        </Typography>
        <nav aria-label="main mailbox folders">
          <List>
            {LIItems.map((v, i) => {
              return (
                <ListItem disablePadding>
                  <ListItemText
                    primary={v}
                    sx={{ ".MuiTypography-root": { fontSize: "12px" } }}
                  />
                </ListItem>
              );
            })}
          </List>
        </nav>
      </div>
      <br />
      <h4>VISA FEES</h4>
      <TableContainer
        sx={{
          border: "1px solid #ded5d5",
          marginTop: "5px !important;",
        }}
      >
        <Table aria-label="simple table" size="small">
          <TableHead sx={{ background: "none !important" }}>
            <TableRow>
              <TableCell style={{ padding: "0px !important" }}>
                Entry Visa Type
              </TableCell>
              <TableCell style={{ padding: "0px !important" }}>
                Visa Fees
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableItems.map((row) => (
              <TableRow
                key={row.type}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  align="left"
                  style={{ fontSize: "12px", color: "rgb(92 88 88 / 87%)" }}
                >
                  {row.type}
                </TableCell>
                <TableCell
                  align="left"
                  style={{ fontSize: "12px", color: "rgb(92 88 88 / 87%)" }}
                >
                  {row.amount}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <div
        style={{
          background: "rgb(204 245 204)",
          padding: "10px",
          borderRadius: 5,
        }}
      >
        <Typography variant="subtitle1" gutterBottom>
          Special Note
        </Typography>
        <Typography
          variant="body1"
          style={{ color: "#514e4e", fontSize: "12px" }}
        >
          VISA PROCESSING FEE IS NON-REFUNDABLE.
        </Typography>
        <Typography
          variant="body1"
          style={{ color: "#514e4e", fontSize: "12px" }}
        >
          PRINT VISA AUTHORITY IN COLOR AND PRESENT IT AT THE POINT OF ENTRY ON
          ARRIVAL.
        </Typography>
      </div>
    </>
  );
};

export default AppInfo;
