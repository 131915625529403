import { useTheme } from "@mui/material";
import { DefaultTheme } from "@mui/styles";
import React from "react";
import RSelect from "react-select";

//Works for both datk and light mode
export const esRSelectStyle = (muiTheme: DefaultTheme) => {
  let isDarkMode = muiTheme.palette.mode === "dark";
  let palette = {
    menuBg: "#1E1E1E",
    menuBorderColor: "rgba(255, 255, 255, 0.16)",
    optionHoverbg: "rgba(255, 255, 255, 0.06)",
    optionHoverTxt: "",
    menuTxtColor: "#fff",
    valueContainerBg: muiTheme.palette.background.paper,
    valueContainerTxt: "rgba(255, 255, 255, 0.24)",
    containerBg: "#1A202C",
  };

  return {
    container: (provided: any, state: any) => {
      return {
        ...provided,
        color: "red",
      };
    },
    placeholder: (provided: { color: any }, state: any) => ({
      ...provided,
      color: isDarkMode ? muiTheme.palette.text.primary : provided.color,
    }),
    valueContainer: (provided: any, state: any) => ({
      ...provided,
      color: "red",
    }),
    noOptionsMessage: (provided: { background: any }, state: any) => ({
      ...provided,
      background: isDarkMode
        ? muiTheme.palette.text.secondary
        : provided.background,
      color: isDarkMode ? muiTheme.palette.background.paper : "black",
    }),
    menu: (provided: { background: any; border: any }, state: any) => ({
      ...provided,
      padding: 0,
      background: isDarkMode ? palette.menuBg : provided.background,
      border: isDarkMode ? "#fff" : provided.border,
    }),
    singleValue: (provided: { color: any }) => ({
      ...provided,
      height: "100%",
      color: isDarkMode ? "#fff" : provided.color,
      paddingTop: "3px",
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      background: muiTheme.palette.background.paper,
      fontSize: "16px", // Font size for edit profile
      boxShadow: "none",
      color: "red",
      minHeight: "40px",
      "&:hover": {
        // backgroundColor: muiTheme.palette.background.paper,
        // minHeight: "40px",
      },
    }),
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
    option: (provided: { background: any }, state: any) => ({
      ...provided,
      borderBottom: "1px solid lightgray",
      background: isDarkMode ? palette.menuBg : provided.background,
      fontWeight: "normal",
      color: muiTheme.palette.text.primary,
      fontSize: "14px",
      "&:hover": {
        background: muiTheme.palette.primary.light,
        color: isDarkMode ? "#000" : muiTheme.palette.text.primary,
      },
      "&:focus": {
        background: muiTheme.palette.primary.light,
        // background: "red",
        color: muiTheme.palette.text.primary,
      },
    }),
    multiValue: (provided: any, state: { isDisabled: any }) => ({
      ...provided,
      opacity: state.isDisabled ? 0.5 : 1,
      transition: "opacity 300ms",
      borderRadius: "8px",
    }),
    input: (provided: any, state: any) => ({
      ...provided,
      color: isDarkMode ? "#fff" : provided.color,
    }),
  };
};
interface Props {
  styles?: any;
  ref?: any;
  options?: any;
  style?: any;
  menuPortalTarget?: any;
  placeholder?: any;
  defaultValue?: any;
  onChange?: any;
  isMulti?: any;
  value?: any;
  otherProp?: any;
  isSearchable?: any;
}
const CustomRSelect = React.forwardRef(
  (
    {
      styles,
      options,
      style,
      menuPortalTarget,
      placeholder,
      defaultValue,
      onChange,
      isMulti,
      value,
      otherProp,
      isSearchable = true,
    }: Props,
    ref: any
  ) => {
    const muiTheme = useTheme();
    return (
      <RSelect
        styles={esRSelectStyle(muiTheme)}
        ref={ref}
        options={options}
        menuPortalTarget={menuPortalTarget}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={onChange}
        isMulti={isMulti}
        isSearchable={isSearchable}
        value={value}
        {...otherProp}
      ></RSelect>
    );
  }
);

export default CustomRSelect;
