import * as React from "react";

const LogoMonogram = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={80} height={80} {...props}>
    <g fillRule="nonzero" fill="none">
      <path
        fill="#6755DF"
        d="M13.75 80C6.16 80 0 73.84 0 66.25S6.16 52.5 13.75 52.5 27.5 58.66 27.5 66.25 21.34 80 13.75 80z"
      />
      <path
        fill="#FFAF00"
        d="M54.905 72.133a13.48 13.48 0 0 0 1.47 2.534C58.764 77.893 62.57 80 66.875 80 74.12 80 80 74.027 80 66.667c0-1.947-.42-3.814-1.155-5.494L52.595 7.867C50.548 3.227 45.954 0 40.625 0 33.38 0 27.5 5.973 27.5 13.333c0 1.947.42 3.814 1.155 5.494l26.25 53.306z"
      />
    </g>
  </svg>
);

export default LogoMonogram;
