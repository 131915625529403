import { createSlice } from "@reduxjs/toolkit";
const idpSlice = createSlice({
  name: "idp",
  initialState: {
    name: "",
    selectedIdpGroup: 0,
  },
  reducers: {
    setLIName: (state, action) => {
      console.log("action.payload", action.payload);
      return { name: action.payload };
    },
    setIdpSelectedGroup: (state, action) => {
      return { selectedIdpGroup: action.payload };
    },
  },
});

const { reducer, actions } = idpSlice;
export const { setLIName, setIdpSelectedGroup } = actions;
export default reducer;
